import { ADFOX_CITIES } from 'constants/Config';

class ReactAdFox {
	async init(id, isMobile = false) {
		this.id = id;
		this.list = [];
		this.city = null;

		window.yaContextCb = window.yaContextCb || [];

		const script = document.createElement('script');
		script.src = 'https://yandex.ru/ads/system/context.js';
		script.async = true;

		setTimeout(() => document.head.appendChild(script), 1000);

		if (!isMobile) this.timer = setInterval(this.reload, 60000);
	}

	setCity(value) {
		const city = Object.entries(ADFOX_CITIES)
			.filter(([k, v]) => v.includes(value))
			.pop();

		this.city = city ? city[0] : null;
	}

	create(props) {
		window.yaContextCb.push(
			// eslint-disable-next-line
			eval(`() => Ya.adfoxCode.create(${JSON.stringify(props)})`)
		);
	}

	put(containerId, params) {
		if (this.city) params.puid1 = this.city;
		const el = document.getElementById(containerId);
		if (el)
			window.yaContextCb.push(
				// eslint-disable-next-line
				eval(`() => {
        Ya.adfoxCode.create({
          ownerId: ${this.id},
          containerId: "${containerId}",
          params: ${JSON.stringify(params)},
			 lazyLoad: { fetchMargin: 200, mobileScaling: 2 }
        })
      }`)
			);
	}

	reload() {
		window.Ya?.adfoxCode?.reload(null, { onlyIfWasVisible: true });
	}

	del(containerId) {
		const el = document.getElementById(containerId);
		if (el) el.innerHTML = '';
	}
}

export default new ReactAdFox();
