import { ALLOW_MIME } from 'constants/Config';
import { observable, toJS, makeAutoObservable } from 'mobx';

import { AttachmentModel } from './Models';

export default class EditorStore {
	constructor(model) {
		this.entity = model;
		this.attachments = observable.array([]);
		this.editMode = false;
		makeAutoObservable(this);
	}

	clear() {
		this.entity.clear();
		this.attachments = [];
		this.editMode = false;
	}

	onChange(value) {
		this.entity.setText(value);
	}

	toJS() {
		return { ...this, attachments: toJS(this.attachments) };
	}

	fromJS(val) {
		this.entity = Object.assign(this.entity, val.entity);
		this.editMode = val.editMode;
		this.attachments = val.attachments;
	}

	setItem(item) {
		if (!item?.id) return;

		this.editMode = true;
		this.entity.setItem(item);
		this.attachments = [];
		if (!item?.attachments?.length) return;

		for (let key in item?.attachments) {
			let model = new AttachmentModel();
			model.setObjectId(this.entity?.objectId || null);
			model.setData(item?.attachments[key]);
			this.attachments.push(model);
		}
	}

	setAttachments(attachments) {
		this.attachments = attachments;
	}

	isEmpty() {
		return this.entity.isEmpty() && this.attachments?.length === 0;
	}

	addAttachments(list, callback, errorCallback) {
		return Array.from(list).forEach((file) => {
			if (!ALLOW_MIME.includes(file.type)) {
				const errorText = `Не поддерживается тип ${file.type}`;

				if (errorCallback) {
					errorCallback(errorText);
				} else {
					console.error(errorText);
				}

				return;
			}

			let model = new AttachmentModel();

			model.setObjectId(this.entity?.objectId || null);
			model.setObjectType(this.entity?.objectType || null);

			this.attachments.push(model);

			model.upload(file).then(() => callback && callback());
		});
	}

	deleteAttachment(item) {
		if (item?.id) {
			let attachs = toJS(this.attachments);
			this.attachments = attachs.filter((attach) => attach.id !== item.id);
		}
	}

	get content() {
		return this.entity?.getText();
	}

	get hasAttachments() {
		return this.attachments?.length > 0;
	}

	get countAttachments() {
		return this.attachments?.length || 0;
	}

	get attachmentsList() {
		return this.attachments
			.filter((item) => item.id)
			.map((item) => item.id)
			.join(',');
	}

	get hasLoadingAttachments() {
		return this.attachments.some(({ uploading }) => uploading);
	}
}
