import classNames from 'classnames';
import { relative } from 'helpers/utils';
import useAuthModal from 'modules/Auth/hooks/useAuthModal';
import { useNavigate } from 'react-router-dom';
import { HStack, Stack } from 'vgui';

import ProfileIcon from './ProfileIcon';

const ProfileHeader = ({
	nick,
	title,
	image,
	url,
	children,
	small,
	status,
	online,
	isAuth = true,
	onClick = () => {},
}) => {
	const navigate = useNavigate();
	const showAuthModal = useAuthModal('profile');

	const handlePressView = () => {
		if (!isAuth) return showAuthModal();
		if (onClick) onClick();
		navigate(relative(url));
	};

	return (
		<HStack className={classNames('w-full', 'gap-2')}>
			<ProfileIcon url={image} alt={nick} status={status?.id} online={online} onClick={handlePressView} />
			<Stack className={'items-start overflow-hidden'}>
				<button onClick={handlePressView} className={'text-left grid'}>
					<span
						className={classNames(small ? 'text-sm' : 'text-base', 'mr-1 text-primary hover:underline truncate')}
					>
						{nick || title}
					</span>
				</button>
				<HStack>{children}</HStack>
			</Stack>
		</HStack>
	);
};

export default ProfileHeader;
