import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { POST } from 'constants/Size';
import { relative } from 'helpers/utils';
import { Link, Photo, Stack } from 'vgui';

import { AttachmentHtml } from './AttachmentHtml';

export const AttachmentArticle = ({ items, url, onOpenPost, full = false }) => {
	if (!items) return null;

	if (full) return <AttachmentHtml content={items[0].context.body} full />;

	const { header, litera, image, body } = items[0].context;
	return (
		<Link to={relative(url)} onClick={onOpenPost}>
			<Stack className="gap-3">
				{image && <Photo url={image} size={POST} mode="crop" placeholder alt={header || 'img'} />}
				{header && <h2 className="mb-0">{header}</h2>}
				{litera && <p className="mb-0">{litera}</p>}
				{body && (
					<div>
						<span className="link mt-2">
							<ArrowRightCircleIcon className="w-4 h-4" />
							Открыть полностью
						</span>
					</div>
				)}
			</Stack>
		</Link>
	);
};
