import { HeartIcon } from '@heroicons/react/24/outline';
import { HeartIcon as HeartSolidIcon } from '@heroicons/react/24/solid';
import { setReaction } from 'actions/reaction';
import useOutsideClick from 'hooks/useOutsideClick';
import useAuthModal from 'modules/Auth/hooks/useAuthModal';
import { Button, HStack } from 'vgui';

import ReactionPic from './ReactionPic';
import ReactionPopup from './ReactionPopup';

const ReactionWidget = ({ id, values, isAuth, disabled = false, onSelect = () => {} }) => {
	const showAuthModal = useAuthModal('reaction');
	const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);
	const { $: selected, ...clearValues } = values;

	let sum = 0;
	const icons = [];

	for (let key in clearValues) {
		icons.push({ key: key, cnt: values[key] });
		sum += values[key];
	}

	const sorted = icons.sort((a, b) => b.cnt - a.cnt);
	const IconsBar = () => (
		<HStack className={'items-center gap-1'}>
			{sorted[0].cnt > 0 ? <ReactionPic className="w-5 h-5" name={sorted[0].key} /> : null}
			{sorted[1].cnt > 0 ? <ReactionPic className="w-5 h-5" name={sorted[1].key} /> : null}
		</HStack>
	);

	let timer = null;
	const togglePopup = () => {
		clearTimeout(timer);
		if (!isComponentVisible) {
			timer = setTimeout(() => {
				setIsComponentVisible(false);
				clearTimeout(timer);
			}, 10000);
		}

		if (selected) {
			handleSelectReaction(null);
		} else {
			handleSelectReaction('like', (prevState) => !prevState);
		}
	};

	const handleSelectReaction = (value, hide = false) => {
		setIsComponentVisible(hide);
		setReaction(id, value);
		onSelect(value);
	};

	const handleClick = () => {
		if (!isAuth) return showAuthModal();
		return togglePopup();
	};

	return (
		<>
			<ReactionPopup
				show={isComponentVisible}
				selected={selected}
				values={clearValues}
				onClick={handleSelectReaction}
				ref={ref}
			/>
			{sum > 0 && <IconsBar />}
			<Button round size="small" variant="secondary" onClick={handleClick} title="Реакции" disabled={disabled}>
				{selected ? <HeartSolidIcon className="w-4 text-gray-500" /> : <HeartIcon className="w-4" />}
				{sum}
			</Button>
		</>
	);
};

export default ReactionWidget;
