import { scrollTop, setTitle } from 'helpers/utils';
import { useLayoutEffect } from 'react';
import { layoutStore } from 'stores';

export default function useLayoutOptions(props) {
	const setLayoutOptions = (options) => {
		layoutStore.setOptions(options);
	};

	useLayoutEffect(() => {
		if (props.title) setTitle(props.title);
		if (props.title && props.header && !props.header.title) props.header.title = props.title;

		setLayoutOptions({
			header: props.header || { title: props.title },
			...props,
		});
	}, [props]);

	return { setLayoutOptions, setTitle, scrollTop };
}
