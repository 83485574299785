import * as types from 'constants/Types';
import { schema } from 'normalizr';

const postSchema = new schema.Entity(types.POSTS);
const groupSchema = new schema.Entity(types.GROUPS);
const promoSchema = new schema.Entity(types.PROMO);
const profileSchema = new schema.Entity(types.PROFILE);
const locationSchema = new schema.Entity(types.LOCATIONS);
const suggestSchema = new schema.Entity(types.SUGGEST);
const vacancySchema = new schema.Entity(types.VACANCY);
const transportSchema = new schema.Entity(types.TRANSPORT);
const complaintSchema = new schema.Entity(types.COMPLAINT);
const notificationSchema = new schema.Entity(types.NOTIFICATIONS);
const payTariffSchema = new schema.Entity(types.PAY_TARIFF);
const advOfferSchema = new schema.Entity(types.ADV);

const activitySchema = new schema.Entity(types.ACTIVITIES, {
	data: {
		post: postSchema,
		vacancy: vacancySchema,
		adv: advOfferSchema,
	},
});

//<messenger>
const messageSchema = new schema.Entity(types.MESSAGE, {
	sender: profileSchema,
});

const chatSchema = new schema.Entity(types.CHAT, {
	last: messageSchema,
});
//</messenger>

const commentSchema = new schema.Entity(types.COMMENTS, {
	author: profileSchema,
});

const memberSchema = new schema.Entity(types.MEMBER);
const followSchema = new schema.Entity(types.FOLLOW);

export {
	postSchema,
	groupSchema,
	activitySchema,
	profileSchema,
	commentSchema,
	locationSchema,
	suggestSchema,
	memberSchema,
	vacancySchema,
	transportSchema,
	promoSchema,
	complaintSchema,
	notificationSchema,
	chatSchema,
	messageSchema,
	payTariffSchema,
	followSchema,
};
