import parse from 'html-react-parser';
import ReactDOMServer from 'react-dom/server';
import emoji from 'react-easy-emoji';

const emojify = (input) => {
	if (!input || /class="emoji"/.test(input)) return input;
	return emoji(input, {
		baseUrl: '//cdn.vashgorod.ru/twemoji/assets/svg',
		size: '',
		ext: '.svg',
		props: {
			className: 'emoji',
			style: {
				height: '1.1em',
				width: '1.1em',
				margin: '0 .2em 0 0',
				verticalAlign: '-0.15em',
			},
		},
	})
		.map((el) => {
			if (el.type === 'img') {
				return ReactDOMServer.renderToStaticMarkup(el);
			}
			return el;
		})
		.join('');
};

const parseEmoji = (content, options = {}) => {
	if (!content) return content;
	return parse(emojify(content), options);
};

const replaceEmoji = (str) => {
	let strCopy = str;
	const emojiKeycapRegex = /[\u0023-\u0039]\ufe0f?\u20e3/g;
	const emojiRegex = /\p{Extended_Pictographic}/gu;
	const emojiComponentRegex = /\p{Emoji_Component}/gu;
	if (emojiKeycapRegex.test(strCopy)) {
		strCopy = strCopy.replace(emojiKeycapRegex, ' ');
	}
	if (emojiRegex.test(strCopy)) {
		strCopy = strCopy.replace(emojiRegex, ' ');
	}
	if (emojiComponentRegex.test(strCopy)) {
		for (const emoji of strCopy.match(emojiComponentRegex) || []) {
			if (/[\d|*|#]/.test(emoji)) {
				continue;
			}
			strCopy = strCopy.replace(emoji, ' ');
		}
	}

	return strCopy;
};

export { emojify, parseEmoji, replaceEmoji };
