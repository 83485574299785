import { getCookie, setCookie } from 'helpers/cookie';
import { useEffect, useState } from 'react';
import { Button, Card, Link } from 'vgui';

const CookieNote = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (!getCookie('cookies_policy')) setShow(true);
	}, []);

	if (!show) return null;

	const handleClick = () => {
		setCookie('cookies_policy', true, 365);
		setShow(false);
	};

	return (
		<Card className="fixed z-[1000] bg-[#fdfae8] p-4 rounded border shadow-lg min-w-[300px] max-w-[96%] md:max-w-[70%] bottom-0 mb-2 md:mb-5 w-full left-[2%] right-[2%] md:left-[20%] md:right-[20%]">
			<span className="flex flex-col md:flex-row items-center md:justify-between gap-2">
				<span className="text-sm">
					<span>
						Мы используем файлы cookie для хранения данных. Продолжая использовать сайт, Вы даете согласие
						на&nbsp; работу с этими файлами.&nbsp;
					</span>
					<Link className={'text-primary underline hover:cursor-pointer hover:no-underline'} to="/legal/privacy">
						Подробнее...
					</Link>
				</span>
				<Button size="small" className="w-1/2 md:w-auto mt-2 md:mt-0 md:px-10" onClick={handleClick}>
					ОК
				</Button>
			</span>
		</Card>
	);
};

export default CookieNote;
