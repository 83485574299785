import { datePretty } from './date';
import plural from './plural';
import toQueryString from './toQueryString';

const rxUrl =
	/(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/|www\.)[a-zа-я0-9]+([-.]{1,2}[a-zа-я0-9]+)*\.[a-zрф]{2,10}(:[0-9]{1,5})?([^<\s]+)?/gm;

function numberFormat(n) {
	return n.toFixed().replace(/\d(?=(\d{3})+$)/g, '$& ');
}

function isURL(url) {
	if (!url) return url;
	return new RegExp('^' + rxUrl.source, rxUrl.flags).test(url.trim());
}

function matchURL(text) {
	return text.match(rxUrl);
}

export { datePretty, toQueryString, plural, numberFormat, isURL, matchURL };
