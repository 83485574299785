import { IMAGE } from 'constants/requestTypes';
import api from 'services/api';
import { requestStore } from 'stores';

export const createAttachment = (params) => {
	return api
		.post('/v1/attachment', params)
		.then(({ data: rawData }) => {
			const { _meta, ...data } = rawData;
			return Promise.resolve(data);
		})
		.catch(() => {
			return Promise.reject();
		});
};

export const uploadAttachment = (file, params, callback) => {
	return api
		.upload('/v1/attachment', file, params, callback)
		.then(({ data: rawData }) => {
			const { _meta, ...data } = rawData;

			return Promise.resolve(data);
		})
		.catch(() => {
			return Promise.reject();
		});
};

export const loadAttachments = (id) => {
	const requestType = IMAGE;

	if (requestStore.getRequestByType(requestType)) return;
	requestStore.setRequestInProcess(requestType, true);

	return api
		.get(`v1/attachment?object_id=${id}`)
		.then(({ data: { _meta, ...data } }) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.resolve(data);
		})
		.catch(() => requestStore.setRequestInProcess(requestType, false));
};

export const getAttachment = (id) => {
	return api.GET(`/v1/attachment/${id}`);
};

export const updateAttachment = (id, context) => {
	return api.PATCH(`/v1/attachment/${id}`, { context });

	// return api
	// 	.patch(`/v1/attachment/${id}`, { context })
	// 	.then(({ data: { _meta, ...data } }) => Promise.resolve(data))
	// 	.catch((e) => Promise.reject(e));
};

export const deleteAttachment = (id) => {
	return api
		.delete(`/v1/attachment/${id}`)
		.then(() => Promise.resolve())
		.catch((e) => Promise.reject(e));
};
