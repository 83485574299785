import { UserPlusIcon, CheckIcon } from '@heroicons/react/24/outline';
import { Button } from 'vgui';

const SubscribeWidget = ({ id, access, onClick = () => {} }) => {
	if (!id) return null;
	if (access !== null && access !== 'new') return null;
	return (
		<Button
			title={access === null ? 'Подписаться' : 'Отписаться'}
			onClick={() => onClick(access === null ? 'subscribe' : 'unsubscribe', id)}
			round
			size="rect"
			variant="transparent"
		>
			{access === 'new' && <CheckIcon className="w-5 h-5" />}
			{!access && <UserPlusIcon className="w-5 h-5" />}
		</Button>
	);
};

export default SubscribeWidget;
