const ComplaintTypes = {
	COMMENT: 5,
	POST: 10,
	GROUP: 15,
	USER: 20,
	TRANSPORT: 25,
	IMAGE: 30,
	VACANCY: 35,
	VIDEO: 40,
	CONTACT: 45,
	ADV: 82,
};

export default ComplaintTypes;
