import { setCountersNotifications } from 'actions/counters';
import {
	addMessage,
	addChat,
	setStatus,
	setCounterChat,
	setCountersMessages,
	updateMessage,
} from 'modules/Messenger/actions';
import api from 'services/api';
import cent from 'services/cent';

export async function connectCent() {
	if (cent.isConnected()) return;
	return api.get('/v1/auth/refresh').then(({ data }) => cent.connect(data.jwt, data.user_id, handler));
}

export async function disconnectCent() {
	cent.disconnect();
}

const handler = (m) => {
	if (!m) return;

	const { action, message, items, counter, chat } = m.data;

	switch (action) {
		case 'new_chat':
			addChat(chat);
			break;

		case 'new_message':
			addMessage(message);
			break;

		case 'update_message':
			updateMessage(message);
			break;

		case 'message_status':
			setStatus(items);
			break;

		case 'counters':
			setCounterChat(counter);
			setCountersMessages(items);
			break;

		case 'notice_counter':
			setCountersNotifications(counter);
			break;

		default:
			break;
	}
};
