import './style.css';

export const Skeleton = ({ id, position, type, children }) => {
	const s = type?.includes('x')
		? {
				width: parseInt(type.split('x')[0]) + 'px',
				height: parseInt(type.split('x')[1]) + 'px',
		  }
		: {
				width: '100%',
				height: '200px',
		  };

	return (
		<div className="apv" style={s}>
			{id}
			<br />
			{position && `pos: ${position}`}
			{type && `, type: ${type}`}
			{children}
		</div>
	);
};
