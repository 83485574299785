import { get } from 'helpers/utils';
import { observable, makeObservable, action, computed, toJS } from 'mobx';

const TYPE = 'entities';

export default class AbstractStore {
	data = observable.map({});
	meta = observable.map({});
	requests = observable.map({});

	constructor() {
		makeObservable(this, {
			data: observable,
			meta: observable,
			requests: observable,

			concat: action,
			ids: action,
			prepend: action,

			setEntity: action,
			getEntity: action,
			updateEntity: action,
			merge: action,
			entities: computed,

			setMeta: action,
			getNext: action,
			getTotal: action,
			hasMore: action,

			setRequestInProcess: action,
			getRequestByType: action,

			clear: action,
		});
	}

	//ids
	concat(key, ids_new) {
		const a = this.data.get(key);
		if (!a) return this.data.set(key, ids_new);

		const b = ids_new.filter((id) => !a.includes(id));
		this.data.set(key, a.concat(b));
	}
	ids(key) {
		return this.data.get(key);
	}
	delete(key, id) {
		const a = this.data.get(key);
		if (!a) return false;

		return this.data.set(
			key,
			a.filter((i) => id !== i),
		);
	}
	prepend(key, ids_new) {
		const a = this.data.get(key);
		if (!a) return this.data.set(key, ids_new);

		const b = ids_new.filter((id) => !a.includes(id));
		this.data.set(key, b.concat(a));
	}

	//entities
	setEntity(entity) {
		this.data.get(TYPE).set(entity.id, entity);
	}
	getEntity(id) {
		return this.data.get(TYPE)?.get(id) || null;
	}
	updateEntity(id, props = {}) {
		const entity = this.getEntity(id);
		if (entity) this.setEntity({ ...toJS(entity), ...props });
	}
	merge(entities_new, clear = false) {
		if (!this.data.get(TYPE) || clear) this.data.set(TYPE, observable.map({}));
		Object.entries(entities_new).forEach(([_, entity]) => this.setEntity(entity));
	}
	get entities() {
		const entities = this.data.get(TYPE);
		return entities ? new Map(entities) : new Map();
	}

	//meta
	setMeta(key, _meta, _links) {
		this.meta.set(key, {
			nextHref: get(_links, 'next.href'),
			currentPage: parseInt(get(_meta, 'currentPage')),
			perPage: parseInt(get(_meta, 'perPage')),
			pageCount: parseInt(get(_meta, 'pageCount')),
			totalCount: parseInt(get(_meta, 'totalCount')),
		});
	}
	getNext = (key) => {
		return this.meta.has(key) ? this.meta.get(key).nextHref : null;
	};
	getTotal = (key) => {
		return this.meta.has(key) ? this.meta.get(key).totalCount : 0;
	};
	hasMore = (key) => {
		return this.meta.get(key)?.nextHref ? true : false;
	};
	decTotal = (key) => {
		const total = this.getTotal(key);
		if (total > 0) this.meta.set(key, { ...this.meta.get(key), totalCount: total - 1 });
	};

	//requests
	setRequestInProcess = (type, inProcess) => {
		this.requests.set(type, inProcess);
	};

	getRequestByType(type) {
		return this.requests.get(type);
	}

	//general
	clear = () => {
		this.data.clear();
		this.meta.clear();
	};
}
