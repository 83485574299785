import { makeAutoObservable, observable } from 'mobx';

class RequestStore {
	loading = false;
	requests = observable.map({});

	setRequestInProcess = (type, inProcess) => {
		this.requests.set(type, inProcess);
		// this.loading = inProcess;
	};

	getRequestByType(type) {
		return this.requests.get(type);
	}
}

const requestStore = makeAutoObservable(new RequestStore());
export default requestStore;
export { RequestStore };
