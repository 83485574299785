import { relative } from 'helpers/utils';
import GroupIcon from 'modules/Group/components/GroupIcon';
import { Link } from 'react-router-dom';
import { HStack, PinIcon, Stack } from 'vgui';

const GroupHeader = ({ title, image, url, children, pinned, onClick = () => {} }) => (
	<HStack className={'flex items-center justify-between relative'}>
		<HStack className={'flex space-x-3'}>
			<Link to={relative(url)} onClick={onClick}>
				<GroupIcon url={image} alt={title} />
			</Link>
			<Stack className={'grid grid-cols-1 leading-3 truncate'}>
				<Link to={relative(url)} className="title truncate" title={title} onClick={onClick}>
					{title}
					{pinned && <PinIcon className="ml-1 w-4 h-4 inline shrink-0" />}
				</Link>
				{children}
			</Stack>
		</HStack>
	</HStack>
);

export default GroupHeader;
