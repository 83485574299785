import { XMarkIcon } from '@heroicons/react/24/outline';
import { ICON, resize } from 'constants/Size';
import { observer } from 'mobx-react';
import { Button, CircularProgressBar } from 'vgui';

const Attachment = observer((props) => {
	const { id, progress, uploading, context, type, onDelete } = props;

	let thumb = <div />;

	switch (type) {
		case 'photo':
			thumb = context.url && (
				<img
					src={resize(context.url, ICON)}
					className="h-full m-auto object-contain bg-gray-50"
					alt={`${type}-${id}`}
				/>
			);
			break;
		case 'video':
			thumb = context.image && (
				<img
					src={resize(context.image, ICON)}
					className="h-full m-auto object-contain bg-gray-50"
					alt={`${type}-${id}`}
				/>
			);
			break;
		case 'link':
			thumb = context.image && (
				<img
					src={resize(context.image, ICON)}
					className="h-full m-auto object-contain bg-gray-50"
					alt={`${type}-${id}`}
					title={context.url}
				/>
			);
			break;
		default:
			break;
	}

	if (!context && !uploading) return null;

	return (
		<div className="border-[1px] rounded overflow-hidden w-20 h-20 bg-white flex flex-col items-center justify-center">
			{uploading ? (
				<CircularProgressBar value={progress} color="#999" />
			) : (
				<>
					<Button
						round={true}
						variant="secondary"
						className="!p-0.5 absolute right-0.5 top-0.5 z-[2]"
						onClick={onDelete}
					>
						<XMarkIcon className="w-4" />
					</Button>
					{thumb}
				</>
			)}
		</div>
	);
});

export default Attachment;
