import { makeAutoObservable, observable, toJS } from 'mobx';

class EntityStore {
	entities = observable.map({});

	syncEntities = (entity, key) => {
		this.entities.get(key).set(entity.id, entity);
	};

	updateEntity(id, key, props = {}) {
		let item = toJS(this.getEntitiesByKey(key).get(id));
		if (item) this.syncEntities({ ...item, ...props }, key);
	}

	mergeEntities = (key, entities, clear = false) => {
		if (!entities) return;

		if (!this.entities.get(key) || clear) {
			this.entities.set(key, observable.map({}));
		}

		Object.entries(entities).forEach(([_, entity]) => this.syncEntities(entity, key));
	};

	concatEntities = (key, entities) => {
		if (!entities) return;

		if (!this.entities.get(key)) this.entities.set(key, observable.map({}));
		Object.entries(entities).forEach(([_, entity]) => {
			if (!this.entities.get(key).has(entity.id)) this.entities.get(key).set(entity.id, entity);
		});
	};

	getEntitiesByKey(key) {
		const entities = this.entities.get(key);
		return entities ? new Map(entities) : new Map();
	}

	copy = (key1, key2, id) => {
		if (this.entities.get(key1) && this.entities.get(key1).has(id))
			this.mergeEntities(key2, [this.entities.get(key1).get(id)]);
	};

	wipe = () => {
		this.entities.clear();
	};

	clear = (key) => {
		this.entities.delete(key);
	};

	get isEmpty() {
		return this.entities.size === 0;
	}
}

const entityStore = makeAutoObservable(new EntityStore());
export default entityStore;
export { EntityStore };
