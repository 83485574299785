import classNames from 'classnames';
import { FULL, resize } from 'constants/Size';
import { useEffect, useState } from 'react';
import { LightBox, Photo, PhotoGrid } from 'vgui';

export const AttachmentPhoto = ({ items, maxHeight, className }) => {
	const [activeView, setView] = useState({
		active: false,
		slide: 1,
	});

	useEffect(() => {
		return () => {
			document.documentElement.classList.remove('fslightbox-open');
			if (document.fullscreenElement) {
				document.exitFullscreen();
			}
		};
	}, []);

	if (!items) return;

	return (
		<div className={classNames(className, 'attachment-photo')}>
			<LightBox
				toggler={activeView.active}
				sources={items.map(({ context }) => resize(context.url, FULL))}
				slide={activeView.slide}
			/>
			{items.length === 1 ? (
				<Photo
					url={items[0].context.url}
					width={items[0].context.width || 1}
					height={items[0].context.height || 1}
					maxHeight={maxHeight}
					mode="resize"
					placeholder
					onClick={() =>
						setView({
							active: !activeView.active,
							slide: 1,
						})
					}
				/>
			) : (
				<PhotoGrid {...{ items, setView, activeView }} />
			)}
		</div>
	);
};
