import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FULL, IMG_DIR, SQUARE } from 'constants/Size';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { HtmlBlock, LightBox, Pressable } from 'vgui';

const CUT_HEIGHT = 64;

const fixImages = (content) => {
	return content.replace(/style="height: 100px;"/g, '').replace(IMG_DIR, 'r/' + SQUARE);
};

export const AttachmentHtml = (props) => {
	const { header, content, full = false, expand = true, onExpand = () => {} } = props;
	const [opened, setOpen] = useState(expand || full);
	const ref = useRef(null);
	const [activeView, setView] = useState({
		active: false,
		slide: 1,
	});
	const [parsedImages, setParsedImages] = useState([]);

	useLayoutEffect(() => {
		if (ref.current?.clientHeight + CUT_HEIGHT > ref.current?.scrollHeight) {
			setOpen(true);
		}
	}, []);

	useEffect(() => {
		if (ref) {
			const imgs = ref.current?.querySelectorAll('img:not(.emoji)');

			setParsedImages([]);

			if (imgs)
				imgs.forEach((element, index) => {
					setParsedImages((p) => [...p, element.src.replace(SQUARE, FULL)]);

					element.onclick = () => {
						setView((p) => ({
							active: !p.active,
							slide: index + 1,
						}));
					};
				});
		}
	}, [ref, content]);

	const handleExpand = (e) => {
		onExpand(e);
		setOpen(true);
	};

	return (
		<div className="attachment-html">
			{header && <h2>{header}</h2>}
			{content && (
				<>
					{parsedImages.length ? (
						<LightBox
							toggler={activeView.active}
							sources={parsedImages}
							slide={activeView.slide}
							key={content.length}
						/>
					) : null}
					<HtmlBlock
						ref={ref}
						content={fixImages(content)}
						className={classNames('inner', { 'max-h-32 md:max-h-48': !opened }, { 'after:hidden': opened })}
					/>
					{!opened && (
						<Pressable className="link" onClick={handleExpand}>
							<ArrowDownCircleIcon className="w-4 h-4" />
							Показать ещё
						</Pressable>
					)}
				</>
			)}
		</div>
	);
};
