const substrHtml = (string, limit) => {
	let test = string.substring(0, limit);

	if (test.indexOf('&')) {
		do {
			test = string.substring(0, limit);
			limit++;
		} while (
			!(test.charAt(test.length - 1) === ';' || test.charAt(test.length - 1) === ' ' || limit > string.length)
		);
	}

	return test;
};

export default substrHtml;
