import PostModal from 'modules/Post/modals/PostModal';

const modalLoader = ({ request }) => {
	const queryParams = new URL(request.url).searchParams;
	const w = queryParams.get('w');
	if (w?.startsWith('post'))
		return {
			type: PostModal,
			id: Number(w.substring(4)),
		};

	return null;
};

export default modalLoader;
