import { ArrowUturnRightIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import useToaster from 'hooks/useToaster';
import useAuth from 'modules/Auth/hooks/useAuth';
import useAuthModal from 'modules/Auth/hooks/useAuthModal';
import { RichEditor } from 'modules/Editor';
import { useEffect, useRef, useMemo } from 'react';
import EditorStore from 'stores/editor/EditorStore';
import { CommentModel } from 'stores/editor/Models';
import { Button, HStack, Stack } from 'vgui';

import { patchComment, postComment } from '../actions';

const CommentEditContainer = (props) => {
	const {
		reply: [replyId, setReplyId],
		edit: [editId, setEdit],
		filter,
		value,
		attachments,
		id,
		disabled = false,
	} = props;

	const showAuthModal = useAuthModal('reaction');

	const { isAuth } = useAuth();

	const ref = useRef();
	const { toastPromise } = useToaster();

	const editor = useMemo(() => {
		return new EditorStore(new CommentModel(filter?.postId));
	}, [filter]);

	const configAttachments = [
		{
			title: 'Фото',
			accept: 'image/*',
		},
	];

	useEffect(() => {
		if (editId) {
			editor.setItem({
				id: id,
				postId: filter?.postId,
				text: value,
				attachments: attachments,
			});
			return;
		} else {
			editor.clear();
		}

		return () => {
			editor.clear();
		};
	}, [editId, editor, id, value, filter, attachments]);

	useEffect(() => {
		if (replyId || editId) ref.current.focus();
	}, [replyId, editId]);

	const handleSendComment = (text, attachments) => {
		if (filter?.postId && (text || attachments?.length > 0)) {
			const data = {
				body: text.replace(/<img alt="(.*?)" .*?>/g, '$1').replace(/&nbsp;/g, ' '),
				post_id: filter?.postId,
				parent_id: replyId || null,
				attachments: attachments,
			};

			if (!id) {
				toastPromise(postComment(filter, data), {
					loading: 'Отправка комментария...',
					success: () => {
						setReplyId(null);
						editor.clear();
						ref.current.focus();
						return 'Комментарий отправлен';
					},
					error: (data) => {
						return data.message;
					},
				});
			} else {
				toastPromise(patchComment(filter, id, data), {
					loading: 'Обновление комментария...',
					success: () => {
						setEdit(false);
						return 'Комментарий обновлен';
					},
					error: (data) => {
						return data.message;
					},
				});
			}
		}
	};

	const cancelComment = () => {
		setReplyId(null);
		setEdit(null);
		editor.clear();
	};

	if (!isAuth)
		return (
			<Stack className="items-center p-4">
				<Button onClick={showAuthModal} variant="secondary">
					Добавить комментарий
				</Button>
			</Stack>
		);

	return (
		<Stack
			className={classNames(
				'gap-2',
				(replyId || editId) && '-mx-4 px-2 pt-2 pb-4 shadow-inner border-b border-dotted',
			)}
		>
			{(replyId || editId) && (
				<HStack className="justify-between ml-2">
					{replyId ? (
						<HStack className="gap-2">
							<ArrowUturnRightIcon className="w-4 h-4 text-muted" />
							<span className="font-medium text-muted text-sm">Ответ на комментарий</span>
						</HStack>
					) : (
						<HStack className="gap-2">
							<PencilIcon className="w-4 h-4 text-gray-500" />
							<span className="font-medium text-muted text-sm">Изменение комментария</span>
						</HStack>
					)}

					{/* <div className="text-sm mx-2">{replyId ? `Ответ на комментарий:` : `Изменение комментария:`}</div> */}
					<Button className="!p-1" variant="transparent" round onClick={cancelComment}>
						<XMarkIcon className="w-4 h-4" />
					</Button>
				</HStack>
			)}
			<RichEditor
				editor={editor}
				disabled={disabled}
				inputRef={ref}
				configAttachments={configAttachments}
				maxCountAttachments={1}
				onSubmit={handleSendComment}
				onEnterPress={handleSendComment}
				placeholder={editId ? '' : 'Мой ' + (replyId ? 'ответ...' : 'комментарий...')}
				className="w-full max-h-[10rem] max-w-[calc(100%-4rem)]"
			/>
		</Stack>
	);
};

export default CommentEditContainer;
