import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { useLayoutEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { ErrorPretty } from './ErrorPretty';

const chunkFailedMessage = /(chunk [\d]+ failed|ChunkLoadError)/;

export const ErrorBoundary = () => {
	const error = useRouteError();

	useLayoutEffect(() => {
		if (chunkFailedMessage.test(error.toString())) {
			window.location.reload();
		} else {
			Sentry.captureException(error);
		}

		console.error(error);
	}, [error]);

	if (chunkFailedMessage.test(error.toString())) return null;

	return (
		<ErrorPretty
			icon={<ExclamationTriangleIcon />}
			title="Произошла ошибка"
			info={error.toString()}
			description="Но мы уже получили отчет и скоро во всём разберёмся."
		/>
	);
};

export default ErrorBoundary;
