import { DEFAULT_LOCATION } from 'constants/Config';
import { updateProfile } from 'modules/Profile/actions';
import { normalize } from 'normalizr';
import { locationSchema } from 'schemas';
import ReactAdFox from 'services/ReactAdFox';
import { localStore } from 'stores/storage';

import * as requestTypes from 'constants/Types';
import api from 'services/api';
import { authStore, entityStore, requestStore } from 'stores';

export async function fetchGeoSuggest(q = '') {
	const url = `/v1/geo?q=${q}`;

	const requestType = requestTypes.SUGGEST;
	if (requestStore.getRequestByType(requestType)) return;
	requestStore.setRequestInProcess(requestType, true);

	return api
		.get(url)
		.then(({ data }) => {
			const normalized = normalize(data, { items: [locationSchema] });
			return Promise.resolve(normalized.entities.locations);
		})
		.finally(() => {
			requestStore.setRequestInProcess(requestType, false);
		});
}

export function getGeoIP() {
	return api
		.get('/v1/geo/ip')
		.then(({ data }) => {
			delete data['_meta'];
			return Promise.resolve(data);
		})
		.catch(() => {
			return Promise.resolve();
		});
}

export function setLocation(location, confirm = true) {
	authStore.setLocation({ ...location, confirm: confirm });
	localStore.setItem('location', JSON.stringify({ ...location, confirm: confirm }));
	entityStore.clear('geo_suggest');
	ReactAdFox.setCity(authStore.location.id);

	setTimeout(() => updateLocation(location, confirm), 1000);
}

async function updateLocation(location) {
	if (authStore.user?.id && authStore.user?.settings?.geo_id !== location.id)
		updateProfile(authStore.user.id, { settings: { geo_id: location.id } });
}

export async function restoreLocation() {
	try {
		const locationLS = JSON.parse(localStore.getItem('location'));
		if (locationLS?.id) return setLocation(locationLS);
	} catch {}

	if (authStore?.user?.geo) return setLocation(authStore.user.geo);

	const location = await getGeoIP();
	return setLocation(location || DEFAULT_LOCATION, false);
}
