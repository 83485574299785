import classNames from 'classnames';
import React from 'react';
import { HStack, Pressable } from 'vgui';

import ReactionPic from './ReactionPic';

const ReactionPopup = React.forwardRef(({ selected, values, show, onClick = () => {} }, ref) => {
	if (show)
		return (
			<div className={'absolute top-[-6rem] left-[-3.5rem] bg-transparent pt-12 pr-[20rem] pb-12 pl-12 z-[1055]'}>
				<HStack
					ref={ref}
					className={
						'bg-white p-[0.25rem] drop-shadow-lg border border-gray-100 border-solid rounded-full whitespace-nowrap'
					}
				>
					{Object.entries(values).map(([key]) => (
						<Pressable
							className={classNames(
								'h-8 w-8 hover:bg-gray-200 rounded-full flex justify-center items-center',
								selected && selected === key && 'bg-gray-200'
							)}
							key={key}
							onClick={() => onClick(key)}
						>
							<ReactionPic name={key} className="w-6 h-6" />
						</Pressable>
					))}
				</HStack>
			</div>
		);

	if (!show) return null;
});

export default ReactionPopup;
