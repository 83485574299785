const getCookie = (key) => (new RegExp((key || '=') + '=(.*?); ', 'gm').exec(document.cookie + '; ') || ['', null])[1];

const setCookie = (key, value, exp) => {
	let date = new Date();
	date.setTime(date.getTime() + exp * 24 * 60 * 60 * 1000);
	const expires = 'expires=' + date.toUTCString();
	document.cookie = key + '=' + value + '; ' + expires + '; path=/';
};

export { getCookie, setCookie };
