import { BASE_DOMAIN } from 'constants/Config';

import { toUnicode } from './punycode.ts';

export function merge(object, values) {
	return Object.assign({}, object, values);
}

export function fill(array, values) {
	return array.map(function (item) {
		return merge(item, values);
	});
}

export function findIndex(array, item) {
	return array.findIndex((i) => {
		return i.id === item.id;
	});
}

export function insert(array, item) {
	return [item, ...array];
}

export function concat(array, item) {
	return [...array, item];
}

export function remove(array, item) {
	const index = findIndex(array, item);
	return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function column(array, column) {
	return array.map((e) => e[column]);
}

export function replace(array, item) {
	const index = findIndex(array, item);
	if (index < 0) return array.concat([item]);

	return array.map((cur, i) => {
		if (i !== index) return cur;
		return {
			...cur,
			...item,
		};
	});
}

export function shuffle(arr) {
	return [...arr].map((_, i, arrCopy) => {
		var rand = i + Math.floor(Math.random() * (arrCopy.length - i));
		[arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]];
		return arrCopy[i];
	});
}

const stringToPath = function (path) {
	if (typeof path !== 'string') return path;
	let output = [];
	path.split('.').forEach(function (item, index) {
		item.split(/\[([^}]+)\]/g).forEach(function (key) {
			if (key.length > 0) output.push(key);
		});
	});
	return output;
};

export function get(obj, path, def) {
	path = stringToPath(path);
	let current = obj;
	for (let i = 0; i < path.length; i++) {
		if (!current[path[i]]) return def;
		current = current[path[i]];
	}
	return current;
}

export function setTitle(title) {
	document.title = title;
}

export function scrollTop(y = 0) {
	window.scrollTo(0, y);
}

export function isPageHidden() {
	return document.hidden || document.msHidden || document.webkitHidden || document.mozHidden;
}

export function getHostname(url, scheme = false) {
	if (!url) return null;
	try {
		const u = new URL(url);
		const h = toUnicode(u.hostname.replace(/^www\./, ''));

		if (!scheme) return h;
		return u.protocol + '://' + h;
	} catch (e) {
		console.error(e);
	}

	return null;
}

export function isLocal(url) {
	if (!url || typeof url === 'number') return false;
	if (url.substring(0, 1) === '/') return true;
	try {
		const u = new URL(url);
		return u && u.hostname === BASE_DOMAIN;
	} catch {}
	return false;
}

export function relative(url, hash = null) {
	try {
		const u = new URL(url);
		return u.pathname + u.search + (hash || u.hash);
	} catch (e) {
		return url;
	}
}

export function isStringEmpty(value) {
	if (!value) return true;
	return value.replace(/<br?>|<?div?>|<?p?>|\n|\r|\s/g, '').length === 0;
}
