import { action, makeObservable } from 'mobx';
import AbstractStore from 'stores/abstractStore';

export default class ChatStore extends AbstractStore {
	sort(key, chatId) {
		if (this.data.get(key)?.length > 1) {
			const a = [chatId];
			const b = this.data.get(key).filter((id) => id !== chatId);
			this.data.set(key, a.concat(b));
		}
	}

	constructor() {
		super();
		makeObservable(this, {
			sort: action,
		});
	}
}
