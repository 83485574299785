import { PlayCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { IS_MOBILE } from 'constants/Config';
import { SQUARE, VIDEO } from 'constants/Size';
import { plural } from 'helpers';
import { Reach } from 'modules/Analytics';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player/lazy';
import { Photo, Spinner } from 'vgui';

const PlayWrapper = ({ loading }) => (
	<div className="play-wrapper">{loading ? <Spinner size="lg" /> : <PlayCircleIcon className="play" />}</div>
);

const size = '500px';

const VideoPlayer = ({
	id,
	postId,
	onEvent = () => {},
	context: { image, platform, url, embed, name, width, height, duration },
	counters,
}) => {
	const [isShow, setIsShow] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isMuted, setIsMuted] = useState(true);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isEnded, setIsEnded] = useState(false);
	const [isWatched, setIsWatched] = useState(false);
	const [isPlayed, setIsPlayed] = useState(false);

	const { ref, inView } = useInView({
		threshold: 1,
		rootMargin: '-48px 0px 0px 0px',
		delay: 1000,
	});

	const sq = height >= width;

	useEffect(() => {
		if (inView) {
			if (platform === 'self' && !IS_MOBILE) setIsShow(true);

			if (isShow && isLoaded && !isEnded) {
				setIsPlaying(true);
			}
		} else if (!inView && isLoaded) {
			setIsPlaying(false);
		}
	}, [inView, isShow, isLoaded, isEnded, platform]);

	const handlePressPlay = () => {
		setIsShow(true);
	};

	const handleClick = (e) => {
		if (isMuted) setIsMuted(false);
		e.preventDefault();
	};

	const handleReady = () => {
		setIsLoaded(true);
		setIsPlaying(true);
	};

	const handleReadyVK = () => {
		handleReady();
		eventPlay();
	};

	const handleEnd = () => {
		setIsEnded(true);
	};

	const handlePlay = () => {
		setIsEnded(false);
		eventPlay();
	};

	const eventPlay = () => {
		if (!isPlayed) {
			setIsPlayed(true);
			if (id) {
				onEvent('post_video_play', { value: id });
				Reach('video_play', { id: `${postId}_${id}` });
			}
		}
	};

	const handleProgress = (e) => {
		if (!isWatched && (e.played === 1 || e.playedSeconds > 10)) {
			setIsWatched(true);
			onEvent('post_video_watch', { value: id });
		}
	};

	const Player =
		platform === 'VK' ? (
			isShow && inView ? (
				<iframe
					title={name}
					src={embed}
					frameBorder="0"
					muted="0"
					width="100%"
					height="100%"
					allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
					allowFullScreen=""
					onLoad={handleReadyVK}
				/>
			) : (
				<div>
					<PlayWrapper platform={platform} />
					<Photo
						url={image}
						mode="resize"
						size={sq ? SQUARE : VIDEO}
						alt={name}
						wrapperClassName="w-full h-full bg-black rounded"
					/>
				</div>
			)
		) : (
			<ReactPlayer
				playing={isPlaying}
				url={url}
				width="100%"
				height="100%"
				className={classNames(`max-h-[${size}]`, sq ? 'aspect-square' : 'aspect-video')}
				controls={true}
				muted={isMuted}
				pip={false}
				loop={false}
				allowFullScreen={false}
				playIcon={<PlayWrapper platform={platform} />}
				onReady={handleReady}
				onClick={handleClick}
				onPlay={handlePlay}
				onEnded={handleEnd}
				onProgress={handleProgress}
				config={{ file: { attributes: { playsInline: true } } }}
			/>
		);

	const totalViews = (counters?.attach_play || 'Нет') + ' просмотр' + plural(counters?.attach_play, '', 'а', 'ов');

	return (
		<div className="attachment-video mb-4">
			<div className="bg-black rounded overflow-hidden">
				<div
					ref={ref}
					className={classNames(
						'player',
						sq ? `aspect-square max-w-[${size}] max-h-[${size}]` : 'aspect-video',
						`platform-${platform.toLowerCase()}`
					)}
				>
					<div
						onClick={handlePressPlay}
						className={classNames('cover w-full h-full t-0 b-0 absolute z-[2]', isLoaded && 'hidden')}
					>
						<PlayWrapper platform={platform} loading={isShow} />
						<Photo
							url={image}
							mode="resize"
							size={sq ? SQUARE : VIDEO}
							alt={name}
							wrapperClassName="w-full h-full bg-black rounded"
							className={sq ? `aspect-square max-w-[${size}] max-h-[${size}]` : 'aspect-video'}
						/>
					</div>
					{isShow && Player}
				</div>
			</div>
			<div className="text-xs pt-2 text-gray-500">{totalViews}</div>
		</div>
	);
};

export const AttachmentVideo = ({ items, postId, onEvent }) => {
	if (!items) return;

	// todo render few videos
	return <VideoPlayer {...items[0]} {...{ postId, onEvent }} />;
};
