export const POSTS = 'POSTS';
export const POSTS_VIEW = 'POST_VIEW';
export const GROUPS = 'GROUPS';
export const COMMENTS = 'COMMENTS';
export const COMMENT_POST = 'COMMENT_POST';
export const COMMENT_PATCH = 'COMMENT_PATCH';
export const REACTION_POST = 'REACTION_POST';
export const PROFILES = 'PROFILE';
export const IMAGE = 'IMAGE';
export const AUTH = 'AUTH';
export const AUTH_CODE = 'AUTH_CODE';
export const SUGGEST = 'SUGGEST';
export const TRANSPORT = 'TRANSPORT';
export const NOTICE = 'NOTICE';
export const VACANCY = 'VACANCY';
