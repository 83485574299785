import { toJS } from 'mobx';

import { POSTS } from '../../constants/Types';
import * as requestTypes from '../../constants/requestTypes';
import api from '../../services/api';
import { entityStore, requestStore } from '../../stores';

export function setReaction(id, value) {
	let changed = false;
	let item = toJS(entityStore.getEntitiesByKey(POSTS).get(id));

	if (item && value) {
		if (item.reaction.$) {
			item.reaction[item.reaction.$]--;
		}
		item.reaction[value]++;
		item.reaction.$ = value;
		item.dt_index = new Date().getUTCSeconds();

		entityStore.syncEntities(item, 'posts');

		changed = true;
	}

	if (!value && item.reaction.$) {
		item.reaction[item.reaction.$]--;
		item.reaction.$ = null;
		item.dt_index = new Date().getUTCSeconds();

		entityStore.syncEntities(item, 'posts');

		changed = true;
	}

	if (!changed) return;

	const requestType = requestTypes.REACTION_POST;
	if (requestStore.getRequestByType(requestType)) return;
	requestStore.setRequestInProcess(requestType, true);

	return api
		.post(`/v1/reaction`, {
			id,
			value,
		})
		.then(({ data }) => {
			return Promise.resolve(data);
		})
		.finally(() => {
			requestStore.setRequestInProcess(requestType, false);
		});
}
