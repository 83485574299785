import { ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { POST } from 'constants/Size';
import { relative } from 'helpers/utils';
import { Attachment } from 'modules/Attachment';
import { Photo, Stack, Link, HtmlBlock, Placeholder } from 'vgui';

import PostLegal from './PostLegal';
import './style.css';

const Attachments = (props) => {
	const { items } = props;
	const types = Object.keys(items);

	if (types.includes('repost')) return <Attachment type="repost" {...props} items={items['repost']} />;
	if (types.includes('vacancy')) return <Attachment type="vacancy" {...props} items={items['vacancy']} />;
	if (types.includes('article')) return <Attachment type="article" {...props} items={items['article']} />;

	if (types.includes('video') && types.includes('link'))
		return (
			<>
				<Attachment type="video" {...props} items={items['video']} />
				<Attachment type="link" {...props} items={items['link']} short />
			</>
		);

	if (types.includes('photo') && types.includes('link'))
		return (
			<>
				<Attachment type="photo" {...props} items={items['photo']} />
				<Attachment type="link" {...props} items={items['link']} short />
			</>
		);

	if (types.includes('video')) return <Attachment type="video" {...props} items={items['video']} />;
	if (types.includes('photo')) return <Attachment type="photo" {...props} items={items['photo']} />;
	if (types.includes('link')) return <Attachment type="link" {...props} items={items['link']} />;

	return null;
};

const BodyNews = ({ url, image, header, litera, onOpenPost }) => (
	<Link to={relative(url)} onClick={onOpenPost}>
		<Stack className="gap-3">
			{image && <Photo url={image} size={POST} mode="crop" placeholder alt={header || 'img'} />}
			{header && <h2 className="mb-0">{header}</h2>}
			{litera && <HtmlBlock content={litera} />}
			<div>
				<span className="link mt-2">
					<ArrowRightCircleIcon className="w-4 h-4" />
					Открыть полностью
				</span>
			</div>
		</Stack>
	</Link>
);

const BodyPost = (props) => {
	const {
		author,
		header,
		body,
		attachments,
		legal,
		url,
		id,
		ck,
		placeholder,
		expand = false,
		onEvent = () => {},
		onExpand = () => {},
		onOpenPost = () => {},
	} = props;
	const hasLink = attachments && Object.keys(attachments).includes('link');

	if (placeholder) return <Placeholder>{placeholder}</Placeholder>;

	return (
		<Stack className="post gap-3">
			{attachments && <Attachments items={attachments} postId={id} {...{ onEvent, onOpenPost, url }} />}
			{(body || header) && (
				<Attachment type="html" content={body} expand={expand} onExpand={(action) => onExpand(id, ck, action)} />
			)}
			{legal && <PostLegal {...legal} author={author} hasLink={hasLink} />}
		</Stack>
	);
};

const PostBody = (props) => {
	return props.type === 'post' ? <BodyPost {...props} /> : <BodyNews {...props} />;
};

export default PostBody;
