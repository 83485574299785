import { GROUPS, PROMO, POSTS } from 'constants/Types';
import { toQueryString } from 'helpers';
import { get } from 'helpers/utils';
import { toJS } from 'mobx';
import { normalize } from 'normalizr';
import { groupSchema, promoSchema } from 'schemas';
import api from 'services/api';
import { entityStore, groupStore, listStore, paginateStore, requestStore, postStore } from 'stores';

export async function fetchGroups(filter = {}, clear = false) {
	const requestType = filter.key;

	const nextHref = paginateStore.getLinkByType(filter.key);
	if (nextHref === undefined && !clear) {
		requestStore.setRequestInProcess(requestType, false);
		return;
	}

	if (requestStore.getRequestByType(requestType)) return Promise.resolve();
	requestStore.setRequestInProcess(requestType, true);

	let route = '/v1/group';
	let params = {
		q: filter.q || filter.qEx,
		region_id: filter.region_id,
		sector: filter.sector,
		type: filter?.type === 'bookmarks' ? filter.type : filter.cat,
		user_id: filter.user_id,
	};

	const initHref = route + '?' + toQueryString(params);
	const url = clear ? initHref : nextHref;

	return api
		.get(url)
		.then(({ data }) => {
			if (clear) groupStore.clear(filter.key);
			const normalized = normalize(data, { items: [groupSchema] });
			entityStore.mergeEntities(GROUPS, normalized.entities.groups);
			groupStore.append(filter.key, normalized.result.items || []);
			paginateStore.setPaginateLink(filter.key, get(data, '_links.next.href'));

			paginateStore.setCounters(filter.key, {
				currentPage: parseInt(get(data, '_meta.currentPage')),
				perPage: parseInt(get(data, '_meta.perPage')),
				pageCount: parseInt(get(data, '_meta.pageCount')),
				totalCount: parseInt(get(data, '_meta.totalCount')),
			});

			requestStore.setRequestInProcess(requestType, false);
			return Promise.resolve();
		})
		.catch((error) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export function getGroup(id, expand = false) {
	const requestType = GROUPS;

	if (requestStore.getRequestByType(requestType)) return Promise.reject();
	requestStore.setRequestInProcess(requestType, true);

	const url = `/v1/group/${id}` + (expand ? '?expand=body,contacts' : '');
	return api
		.get(url)
		.then(({ data }) => {
			delete data['_meta'];
			const normalized = normalize(data, groupSchema);
			entityStore.mergeEntities(requestType, normalized.entities.groups);
			requestStore.setRequestInProcess(requestType, false);
			return Promise.resolve(normalized.result);
		})
		.catch((error) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export async function fetchPromo(filter = {}, clear = false) {
	const requestType = filter.key;

	if (requestStore.getRequestByType(requestType)) return Promise.resolve();
	requestStore.setRequestInProcess(requestType, true);

	let route = '/v1/group';
	let params = {
		city_id: filter.cityId,
		type: 'promo',
	};

	if (filter?.limit) params.limit = filter.limit;

	const url = route + '?' + toQueryString(params);

	return api
		.get(url)
		.then(({ data }) => {
			if (clear) groupStore.clear(filter.key);
			const normalized = normalize(data, { items: [promoSchema] });
			entityStore.mergeEntities(PROMO, normalized.entities.promo);
			listStore.append(filter.key, normalized.result.items);
			requestStore.setRequestInProcess(requestType, false);
			paginateStore.setPaginateLink(filter.key, get(data, '_links.next.href'));
			return Promise.resolve();
		})
		.catch((error) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export function initPromo(items) {
	entityStore.mergeEntities(PROMO, items);
	entityStore.getEntitiesByKey(GROUPS).forEach((g) => {
		items.forEach((i) => {
			if (i.id === g.id) {
				entityStore.updateEntity(i.id, PROMO, { ...i, access: g.access });
			}
		});
	});
}

export function patchGroup(id, data) {
	const requestType = GROUPS;

	requestStore.setRequestInProcess(requestType, true);

	return api
		.patch(`/v1/group/${id}`, data)
		.then(({ data }) => {
			delete data['_meta'];
			const normalized = normalize(data, groupSchema);
			entityStore.mergeEntities(GROUPS, normalized.entities.groups);
			return Promise.resolve(data.item);
		})
		.catch((error) => {
			return Promise.reject(error);
		})
		.finally(() => {
			requestStore.setRequestInProcess(requestType, false);
		});
}

export function actionGroup(action, id) {
	return new Promise((resolve) => {
		patchGroup(id, { action: action });

		let item = toJS(entityStore.getEntitiesByKey(GROUPS).get(id));

		if (item) {
			switch (action) {
				case 'subscribe':
					item.subscription.role = 'reader';
					item.counters.tag_members++;
					break;
				case 'unsubscribe':
					item.subscription.role = null;
					item.subscription.notice = false;
					item.counters.tag_members--;
					break;
				case 'notice-trend':
					item.subscription.notice = 5;
					break;
				case 'notice-all':
					item.subscription.notice = 1;
					break;
				case 'notice-none':
					item.subscription.notice = 0;
					break;
				case 'mark':
					item.params.bookmark = true;
					groupStore.prepend('bookmarks_group', [id]);
					break;
				case 'unmark':
					item.params.bookmark = false;
					groupStore.remove('bookmarks_group', id);
					break;
				default:
					break;
			}

			entityStore.updateEntity(id, GROUPS, { ...item });
		}

		if (action === 'subscribe' || action === 'unsubscribe') {
			// groups
			entityStore.getEntitiesByKey(POSTS).forEach((p) => {
				if (p.group && p.group.id === id) {
					let post = toJS(p);
					post.decoration.header.access = action === 'subscribe' ? 'new' : null;
					entityStore.updateEntity(post.id, POSTS, { ...post });
				}
			});

			// promo
			entityStore.updateEntity(id, PROMO, { access: action === 'subscribe' ? 'new' : null });
		}
		return resolve(item);
	});
}

export function updateGroup(id, data) {
	const requestType = GROUPS;
	if (requestStore.getRequestByType(requestType)) return;
	requestStore.setRequestInProcess(requestType, true);

	return api
		.patch(`/v1/group/${id}`, data)
		.then(({ data }) => {
			delete data['_meta'];
			const normalized = normalize(data, groupSchema);
			entityStore.mergeEntities(GROUPS, normalized.entities.groups);
			postStore.clear('group_' + id);
			return Promise.resolve();
		})
		.catch((error) => {
			const message = error?.response?.data?.message || error?.response?.message;
			return Promise.reject(message);
		})
		.finally(() => {
			requestStore.setRequestInProcess(requestType, false);
		});
}

export function createGroup(data) {
	return api.POST(`/v1/group`, data).then((item) => {
		const normalized = normalize(item, groupSchema);
		if (normalized.result) entityStore.mergeEntities(GROUPS, normalized.entities.groups);
		return Promise.resolve(normalized.result);
	});
}
