import { makeAutoObservable } from 'mobx';

import AbstractStore from './abstractStore';
import authStore from './authStore';
import ChatStore from './chatStore';
import entityStore from './entityStore';
import filterStore from './filterStore';
import layoutStore from './layoutStore';
import listStore, { ListStore } from './listStore';
import paginateStore from './paginateStore';
import requestStore from './requestStore';

const postStore = makeAutoObservable(new ListStore());
const groupStore = makeAutoObservable(new ListStore());
const activityStore = makeAutoObservable(new ListStore());
const profileStore = makeAutoObservable(new ListStore());
const contactStore = makeAutoObservable(new ListStore());
//messenger
const chatStore = new ChatStore();
const messageStore = new AbstractStore();

export function wipe() {
	profileStore.wipe();
	groupStore.wipe();
	postStore.wipe();
	chatStore.clear();
	messageStore.clear();
	listStore.wipe();
	entityStore.wipe();
	authStore.wipe();
}

export {
	layoutStore,
	authStore,
	contactStore,
	listStore,
	entityStore,
	requestStore,
	paginateStore,
	postStore,
	activityStore,
	groupStore,
	profileStore,
	chatStore,
	messageStore,
	filterStore,
};
