export function storageFactory(getStorage: () => Storage): Storage {
	let inMemoryStorage: { [key: string]: string } = {};
	let supported: undefined | boolean;

	const testKey = '0$9a8sd3';

	function isSupported() {
		if (supported !== undefined) return supported;

		try {
			getStorage().setItem(testKey, testKey);
			getStorage().removeItem(testKey);
			supported = true;
		} catch (e) {
			supported = false;
		}

		return supported;
	}

	function clear(): void {
		if (isSupported()) {
			getStorage().clear();
		} else {
			inMemoryStorage = {};
		}
	}

	function getItem(name: string): string | null {
		if (isSupported()) {
			return getStorage().getItem(name);
		}
		if (inMemoryStorage.hasOwnProperty(name)) {
			return inMemoryStorage[name];
		}
		return null;
	}

	function key(index: number): string | null {
		if (isSupported()) {
			return getStorage().key(index);
		} else {
			return Object.keys(inMemoryStorage)[index] || null;
		}
	}

	function removeItem(name: string): void {
		if (isSupported()) {
			getStorage().removeItem(name);
		} else {
			delete inMemoryStorage[name];
		}
	}

	function setItem(name: string, value: string): void {
		if (isSupported()) {
			getStorage().setItem(name, value);
		} else {
			inMemoryStorage[name] = String(value); // not everyone uses TypeScript
		}
	}

	function length(): number {
		if (isSupported()) {
			return getStorage().length;
		} else {
			return Object.keys(inMemoryStorage).length;
		}
	}

	return {
		getItem,
		setItem,
		removeItem,
		clear,
		key,
		get length() {
			return length();
		},
	};
}

export default storageFactory;
