import classNames from 'classnames';
import { useEffect } from 'react';
import ReactAdFox from 'services/ReactAdFox';

import { Skeleton } from './Skeleton';

export const Adfox = (props) => {
	const pv = window.location.search.includes('??');

	useEffect(() => {
		if (!pv) ReactAdFox.create(props);
		return () => !pv && ReactAdFox.del(props.containerId);
	}, [props, pv]);

	const { containerId, position } = props;

	return (
		<div id={containerId} className={classNames('apb flex justify-center')}>
			{pv && <Skeleton id={containerId} position={position} />}
		</div>
	);
};
