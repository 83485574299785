export const POSTS = 'posts';
export const GROUPS = 'groups';
export const ACTIVITIES = 'activities';
export const PROFILE = 'profile';
export const PROFILE_VIEW = 'profile_view';
export const COMMENTS = 'comments';
export const SUGGEST = 'suggest';
export const MEMBER = 'member';
export const FOLLOW = 'follow';

export const TYPE_VIEW = 'view';
export const TYPE_LIST = 'list';

export const ACTION_SEND = 'post';
export const ACTION_VIEW = 'view';

// job
export const VACANCY = 'vacancy';

// transport
export const TRANSPORT = 'transport';
export const LOCATIONS = 'locations';

// slug
export const SLUG = 'slug';

export const PROMO = 'promo';

// complaint
export const COMPLAINT = 'complaint';

//notifications
export const NOTIFICATIONS = 'notifications';

//messenger
export const MESSAGE = 'message';
export const CHAT = 'chat';
export const CHAT_TYPE_P2P = 1;

//welcome
export const INTEREST = 'interest';

// pay
export const PAY_TARIFF = 'pay_tariff';

export const CONTACTS = 'contacts';
export const CONTACT = 'contact';

export const ADV = 'adv';
