class ReactLI {
	init(acc) {
		this.url = 'https://counter.yadro.ru/hit;' + acc;

		const screen = window.screen;
		this.screen =
			typeof screen == 'undefined'
				? ''
				: ';s' +
				  screen.width +
				  '*' +
				  screen.height +
				  '*' +
				  (screen.colorDepth ? screen.colorDepth : screen.pixelDepth);
	}

	hit(url) {
		if (!this.url) return;
		new Image().src =
			this.url +
			'?r' +
			escape(document.referrer) +
			this.screen +
			';u' +
			escape(document.URL) +
			';h' +
			escape(document.title.substring(0, 150)) +
			';' +
			Math.random();
	}
}

export default new ReactLI();
