import deletedImage from 'assets/images/deleted.svg';
import defaultImage from 'assets/images/nophoto.svg';
import classNames from 'classnames';
import { Avatar } from 'vgui';

import { isDeleted } from '../helpers/ProfileHelpers';

const ProfileIcon = ({ url, size = 'xs', className, status, ...props }) => {
	return (
		<Avatar
			url={isDeleted(status) ? deletedImage : url || defaultImage}
			size={size}
			className={classNames('profile-icon object-cover hover:cursor-pointer rounded-full', className)}
			{...props}
		/>
	);
};

export default ProfileIcon;
