import { useRef } from 'react';

const safeDocument = typeof document !== 'undefined' ? document : {};

/**
 * Использование:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
const useScrollBlock = () => {
	const scrollBlocked = useRef();
	const html = safeDocument.documentElement;
	const { body } = safeDocument;

	const blockScroll = () => {
		if (!body || !body.style || scrollBlocked.current) return;

		const scrollBarWidth = window.innerWidth - html.clientWidth;
		const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0;

		/**
		 * 1. Исправлена ошибка в iOS и настольном Safari, из-за которой настройка
		 *    `overflow: hidden` для html/body не мешает прокрутке.
		 * 2. Исправлена ошибка в настольном Safari, из-за которой `overflowY` не мешает
		 *    прокрутке, если `overflow-x` стиль также применяется к телу.
		 */
		html.style.position = 'relative'; /* [1] */
		html.style.overflow = 'hidden'; /* [2] */
		body.style.position = 'relative'; /* [1] */
		body.style.overflow = 'hidden'; /* [2] */
		body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

		scrollBlocked.current = true;
	};

	const allowScroll = () => {
		if (!body || !body.style || !scrollBlocked.current) return;

		html.style.position = '';
		html.style.overflow = '';
		body.style.position = '';
		body.style.overflow = '';
		body.style.paddingRight = '';

		scrollBlocked.current = false;
	};

	return [blockScroll, allowScroll];
};

export default useScrollBlock;
