import PostViewContainer from 'modules/Post/containers/PostViewContainer';
import { Modal } from 'vgui';

const PostModal = ({ onClose, id }) =>
	id && (
		<Modal onHide={onClose} className="modal-preview">
			<Modal.Body onHide={onClose}>
				<PostViewContainer id={id} modal onClose={onClose} />
			</Modal.Body>
		</Modal>
	);

export default PostModal;
