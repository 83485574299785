import toast from 'react-hot-toast';

const useToaster = () => {
	//Документация по кастомизации тостов:
	//https://react-hot-toast.com/docs/toast

	const options = { className: 'toast', duration: 5000 };

	const toastSuccess = (text = 'Успех!') => {
		toast.success(text, options);
	};

	const toastError = (text = 'Ошибка!') => {
		toast.error(text, options);
	};

	const toastLoading = (text = 'Загрузка...') => {
		toast.loading(text, options);
	};

	//toastPromise(fetchAction, {
	//    loading: 'Отправка',
	//    success: 'Сообщение отправлено',
	//    error: 'Ошибка отправки',
	// })
	const toastPromise = (promise, type) => {
		toast.promise(promise, type, options);
	};

	return { toastSuccess, toastError, toastLoading, toastPromise };
};

export default useToaster;
