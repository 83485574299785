import { PaperClipIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import useToaster from 'hooks/useToaster';
import { observer } from 'mobx-react';
import { Attachment } from 'modules/Editor';
import DragArea from 'modules/Editor/components/DragArea';
import { useEffect, useRef } from 'react';
import { Stack, HStack, Editor, Button, DropdownNew, InputNew } from 'vgui';

const RichEditor = observer(
	({
		editor,
		inputRef = useRef(),
		className,
		onChange,
		placeholder = 'Введите текст',
		disabled = false,
		autofocus = false,
		border = true,
		useEmoji = true,
		configAttachments = [],
		maxCountAttachments = 1,
		onSubmit,
		onEnterPress,
		useDragArea = false,
		dragAreaTarget,
	}) => {
		const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);
		const fileRef = useRef();
		const isAndroid = /(android)/i.test(navigator.userAgent);
		const { toastError } = useToaster();

		useEffect(() => {
			if (autofocus) inputRef.current.focus();
		}, [autofocus, inputRef]);

		let timer = null;
		const toggleDropDown = () => {
			clearTimeout(timer);
			if (!isComponentVisible) {
				timer = setTimeout(() => {
					setIsComponentVisible(false);
					clearTimeout(timer);
				}, 100000);
			}

			setIsComponentVisible((prevState) => !prevState);
		};

		const handleAddFiles = (accept) => {
			fileRef.current.focus();
			fileRef.current.value = null;
			if (!isAndroid) fileRef.current.accept = accept;
			fileRef.current.click();
		};

		const handleOnChangeInput = (e) => {
			let files = e.target.files;

			if (fileRef.current?.accept)
				files = Array.from(files).filter((file) => !!file.type.match(fileRef.current?.accept.replace('/', '.')));

			handleAddFilesToEditor(files);
		};

		const handlePaste = (data) => {
			handleAddFilesToEditor(data?.files);
		};

		const handleAddFilesToEditor = (files) => {
			if (files?.length + editor.countAttachments > maxCountAttachments)
				return toastError(
					`Вы не можете прикрепить больше ${maxCountAttachments} ${maxCountAttachments > 1 ? 'файлов' : 'файла'}.`,
				);

			if (files?.length)
				editor.addAttachments(
					files,
					() => onChange(),
					(text) => toastError(text),
				);
		};

		const options = configAttachments.map((item) => {
			return {
				value: item?.title,
				onClick: () => handleAddFiles(item?.accept),
			};
		});

		if (!editor) return null;

		return (
			<div className="w-full h-full">
				{useDragArea && <DragArea onSelect={handleAddFilesToEditor} dragAreaTarget={dragAreaTarget} />}
				<Stack className="gap-y-3 w-full flex-initial">
					{editor.hasAttachments && (
						<HStack className="w-full gap-2 flex-nowrap overflow-y-hidden overflow-x-auto mx-2">
							{editor.attachments.map((item, key) => (
								<div key={key} className="inline-block">
									<Attachment
										{...item}
										onDelete={() => {
											editor.deleteAttachment(item);
											onChange();
										}}
									/>
								</div>
							))}
						</HStack>
					)}

					<HStack className="gap-1 items-end w-full">
						{configAttachments && (
							<>
								<InputNew
									multiple
									ref={fileRef}
									onChange={handleOnChangeInput}
									className="hidden"
									type="file"
								/>
								<div>
									<Button
										ref={ref}
										className={classNames('!p-1 mb-1 text-gray-600 hover:text-gray-900 bg-gray-200')}
										variant="success-fill"
										round
										onClick={toggleDropDown}
									>
										<PaperClipIcon className="w-5 h-5" />
									</Button>
									<DropdownNew
										{...{ isComponentVisible, setIsComponentVisible, ref }}
										className="left-0 min-w-[10rem]"
										options={options}
									/>
								</div>
							</>
						)}

						<Editor
							ref={inputRef}
							border={border}
							useEmoji={useEmoji}
							value={editor?.content}
							onPaste={handlePaste}
							onChange={(e) => {
								editor?.onChange(e);
								if (onChange) onChange(e);
							}}
							onEnterPress={() => onEnterPress(editor?.content, editor?.attachmentsList)}
							className={className}
							placeholder={placeholder}
						/>

						<Button
							className="!p-1 mb-1"
							variant="success"
							round
							onClick={() => onSubmit(editor?.content, editor?.attachmentsList)}
							disabled={editor.isEmpty() || disabled || editor.hasLoadingAttachments}
						>
							<PaperAirplaneIcon className="w-5 h-5" />
						</Button>
					</HStack>
				</Stack>
			</div>
		);
	},
);
export default RichEditor;
