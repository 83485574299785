import { ADFOX_ID, DEV, GA_TRACKING_ID, LIVEINTERNET_ACC, METRIKA_ID, MTX_ID, IS_MOBILE } from 'constants/Config';
import React, { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import ym, { YMInitializer } from 'react-yandex-metrika';
import Metrix from 'services/Metrix';
import ReactAdFox from 'services/ReactAdFox';
import ReactLI from 'services/ReactLI';

const log = (text, ...args) => console.debug('[analytics] ' + text, ...args);

const Analytics = () => {
	useEffect(() => {
		Metrix.init(MTX_ID);
		ReactAdFox.init(ADFOX_ID, IS_MOBILE);

		if (DEV) return log('emulation mode');

		ReactGA.initialize(GA_TRACKING_ID);
		ReactLI.init(LIVEINTERNET_ACC);
	}, []);

	return DEV ? null : <YMInitializer accounts={[METRIKA_ID]} />;
};

const Tracker = () => {
	const location = useLocation();
	const timer = useRef(null);

	useEffect(() => {
		if (timer.current) clearTimeout(timer.current);

		timer.current = setTimeout(() => {
			updateLocation(location);
		}, 500);

		return () => {
			clearTimeout(timer.current);
		};
	}, [location]);
};

const updateLocation = (location) => {
	let url = location.pathname + location.search;

	if (url.match(/\?w=(post|vacancy)/)) url = url.replace(/^(.*)\?w=/, '/');

	if (DEV) return log('hit: ' + url);

	ReactGA.send({ hitType: 'pageview', page: url });
	ym('hit', url);
	ReactLI.hit(url);
};

const Reach = (name, params = {}) => {
	if (DEV) return log('event: ' + name, params);
	Metrix.push(name, params);
};

const Goal = (name) => {
	if (DEV) return log('goal: ' + name);
	ym('reachGoal', name);
};

export { Tracker, Reach, Goal };

export default Analytics;
