import { lazy } from 'react';
import { useModal } from 'react-modal-hook';

const ComplaintModal = lazy(() => import('../modals/ComplaintModal'));

export default function useComplaintModal({ id, type }) {
	const [showModal, hideModal] = useModal(() => <ComplaintModal onHide={hideModal} id={id} type={type} />, [id, type]);

	return showModal;
}
