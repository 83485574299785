import { makeAutoObservable, observable } from 'mobx';

class PaginateStore {
	counters = observable.map({});
	filters = observable.map({});

	constructor() {
		this.links = {};
		this.ts = {};
	}

	setPaginateLink = (paginateType, nextHref) => {
		this.links[paginateType] = nextHref;
	};

	getLinkByType(paginateType) {
		return this.links[paginateType];
	}

	hasMore = (paginateType) => {
		return this.links[paginateType] ? true : false;
	};

	setCounters = (paginateType, value) => {
		this.counters.set(paginateType, value);
		if (value?.currentPage < 2) this.ts[paginateType] = new Date().getTime();
	};

	getCounters = (paginateType) => {
		return this.counters.get(paginateType);
	};

	isExpired = (paginateType, ttl = 60) => {
		return this.ts[paginateType] ? Math.floor((new Date().getTime() - this.ts[paginateType]) / 1000) >= ttl : true;
	};

	setFilter = (paginateType, value) => {
		this.filters.set(paginateType, value);
	};

	getFilter = (paginateType) => {
		return this.filters.get(paginateType);
	};
}

const paginateStore = makeAutoObservable(new PaginateStore());
export default paginateStore;
export { PaginateStore };
