import { toUnicode } from 'helpers/punycode.ts';
import React from 'react';
import { Link } from 'vgui';

const PostLegal = ({ author, source, license, hasLink = false, full = false }) => (
	<>
		{source && source.name && !hasLink && (
			<div className="text-muted text-xs self-start">
				<span>Источник: </span>
				<Link to={source.url} className="link !text-muted">
					{toUnicode(decodeURI(source.name))}
				</Link>
			</div>
		)}
		{source && source.photo && full && (
			<div className="text-muted text-xs self-start">
				<span>Источник фото: </span>
				{toUnicode(decodeURI(source.photo))}
			</div>
		)}
		{license && license.length && <div className="text-muted text-xs self-start">{license[0].title}</div>}
	</>
);
export default PostLegal;
