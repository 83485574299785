import { ACTION_SEND, COMMENTS, PROFILE } from 'constants/Types';
import { toQueryString } from 'helpers';
import { get } from 'helpers/utils';
import { toJS } from 'mobx';
import { normalize } from 'normalizr';
import { commentSchema } from 'schemas';
import api from 'services/api';
import { entityStore, listStore, paginateStore, requestStore } from 'stores';

export function fetchComments(filter, clear = false) {
	const nextHref = paginateStore.getLinkByType(filter.key);

	if (nextHref === undefined && !clear) {
		requestStore.setRequestInProcess(filter.key, false);
		return Promise.resolve([]);
	}

	if (requestStore.getRequestByType(filter.key)) return Promise.resolve([]);
	requestStore.setRequestInProcess(filter.key, true);

	let params = {
		sort: filter.newFirst ? '-id' : 'id',
		limit: filter.limit || 500,
	};
	if (filter.postId) params['post_id'] = filter.postId;
	if (filter.groupId) params['group_id'] = filter.groupId;
	if (filter.feedId) params['feed_id'] = filter.feedId;

	const initHref = '/v1/comment/?' + toQueryString(params);
	const url = (clear ? null : nextHref) || initHref;

	return api.get(url, { cache: false }).then(({ data }) => {
		if (!data['items']) {
			requestStore.setRequestInProcess(filter.key, false);
			return Promise.resolve([]);
		}

		if (clear) listStore.clear(filter.key);

		const normalized = normalize(data, { items: [commentSchema] });
		entityStore.mergeEntities('comments', normalized.entities.comments);
		entityStore.mergeEntities(PROFILE, normalized.entities.profile);
		listStore.append(filter.key, normalized.result.items || []);
		paginateStore.setPaginateLink(filter.key, get(data, '_links.next.href'));
		paginateStore.setCounters(filter.key, get(data, '_meta.totalCount', 0));
		requestStore.setRequestInProcess(filter.key, false);
		return Promise.resolve(normalized.result.items);
	});
}

export function postComment(filter, data) {
	const requestType = COMMENTS + '_' + ACTION_SEND;

	if (requestStore.getRequestByType(requestType)) return;
	requestStore.setRequestInProcess(requestType, true);

	return api
		.post(`/v1/comment`, data)
		.then(({ data }) => {
			delete data['_meta'];
			const normalized = normalize(data, commentSchema);

			entityStore.concatEntities('comments', normalized.entities.comments);
			entityStore.concatEntities(PROFILE, normalized.entities.profile);
			listStore.append(filter.key, [normalized.result]);

			const cnt = paginateStore.getCounters(filter.key);
			paginateStore.setCounters(filter.key, cnt + 1);

			return Promise.resolve(data.item);
		})
		.catch(({ response }) => {
			return Promise.reject(response.data);
		})
		.finally(() => {
			requestStore.setRequestInProcess(requestType, false);
		});
}

export function deleteComment(id, action) {
	let changed = false;

	let item = toJS(entityStore.getEntitiesByKey(COMMENTS).get(id));
	if (item) {
		item.deleted = action === 'delete' ? true : false;
		entityStore.syncEntities(item, COMMENTS);
		changed = true;
	}

	if (!changed) return;

	const requestType = COMMENTS + '_' + id + '_' + action;
	if (requestStore.getRequestByType(requestType)) return;
	requestStore.setRequestInProcess(requestType, true);

	return api
		.patch('/v1/comment/' + id, { action: action })
		.then(() => {
			return Promise.resolve();
		})
		.catch((error) => Promise.reject(error))
		.finally(() => {});
}

export const patchComment = async (filter, id, params) => {
	const requestType = COMMENTS + ACTION_SEND;

	if (requestStore.getRequestByType(requestType)) return;

	requestStore.setRequestInProcess(requestType, true);
	try {
		const data = await api.patch('/v1/comment/' + id, params);
		const { _meta, ...item } = data.data;
		const normalized = normalize(item, commentSchema);

		entityStore.mergeEntities('comments', normalized.entities.comments);
		entityStore.mergeEntities(PROFILE, normalized.entities.profile);
		listStore.append(filter.key, [normalized.result]);
	} catch (error) {
		throw error;
	} finally {
		requestStore.setRequestInProcess(requestType, false);
	}
};
