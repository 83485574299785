import { createElement, memo } from 'react';

import { AttachmentArticle } from './components/AttachmentArticle';
import { AttachmentHtml } from './components/AttachmentHtml';
import { AttachmentLink } from './components/AttachmentLink';
import { AttachmentPhoto } from './components/AttachmentPhoto';
import { AttachmentRepost } from './components/AttachmentRepost';
import { AttachmentVacancy } from './components/AttachmentVacancy';
import { AttachmentVideo } from './components/AttachmentVideo';
import './style.css';

const types = {
	html: AttachmentHtml,
	article: AttachmentArticle,
	repost: AttachmentRepost,
	vacancy: AttachmentVacancy,
	link: AttachmentLink,
	video: AttachmentVideo,
	photo: AttachmentPhoto,
};

const Attachment = memo(
	(props) => (types[props.type] ? createElement(types[props.type], props) : null),
	(a, b) => a.props === b.props
);

export {
	Attachment,
	AttachmentLink,
	AttachmentHtml,
	AttachmentPhoto,
	AttachmentVideo,
	AttachmentVacancy,
	AttachmentRepost,
	AttachmentArticle,
};
