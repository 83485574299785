import * as Sentry from '@sentry/react';
import { getCookie, setCookie } from 'helpers/cookie';
import { uuid } from 'helpers/uuid';
import api from 'services/api';
import { authStore, layoutStore } from 'stores';
import { localStore } from 'stores/storage';

import { connectCent } from './cent';

export async function getMe() {
	return api.get(`/v1/profile/me`, { cache: false }).then(({ data }) => {
		authStore.setUser(data);
		layoutStore.setCountNotice(data.counters?.new_notices || 0);
		layoutStore.setCountChat(data.counters?.new_messages || 0);
		connectCent();
		return Promise.resolve();
	});
}

export async function setToken(token) {
	api.token = token;
	if (token) {
		localStore.setItem('token', token);
		await getMe();
	} else localStore.removeItem('token');
}

export async function restoreDeviceId() {
	let deviceId = localStore.getItem('device_id');
	if (!deviceId) deviceId = getCookie('device_id');
	if (!deviceId) {
		deviceId = uuid();
		localStore.setItem('device_id', deviceId);
	}
	api.deviceId = deviceId;
	setCookie('device_id', deviceId, 365);
	Sentry.setTag('device.id', deviceId);
}

export async function restoreAuth() {
	let token = localStore.getItem('token');
	if (!token || token === 'undefined') {
		localStore.removeItem('token');
		return;
	}

	await setToken(token);
}
