import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { ErrorPretty } from '../components/ErrorPretty';
import { useLayoutOptions } from 'modules/Layout';
import { useLocation } from 'react-router-dom';

const ErrorNotFound = () => {
	const location = useLocation();

	useLayoutOptions({
		title: 'Ошибка 404',
		hideAppBar: true,
		hideAside: true,
		hideNavigator: true,
	});

	return (
		<ErrorPretty
			icon={<QuestionMarkCircleIcon />}
			title="Страница не найдена"
			info={location.pathname}
			description="Возможно, она потерялась или пока не существует."
		/>
	);
};

export default ErrorNotFound;
