export const IMG_DIR = /uploads\/images/g;

export const COVER = '640x210'; // 3:1
export const POST = '640x320'; // 2:1
export const THUMBNAIL = '200x200';
export const ICON = '100x100';
export const SQUARE = '640x640';
export const VIDEO = '640x360'; // 16x9
export const FULL = '1200x1200'; // max side
export const MAX_HEIGHT = 480;

export function crop(url, size, sc = 1) {
	return url.replace(IMG_DIR, 'c/' + scale(size, sc));
}

export function resize(url, size, sc = 1) {
	return url.replace(IMG_DIR, 'r/' + scale(size, sc));
}

function scale(size, scale = 1) {
	if (scale === 1) return size;
	const [w, h] = size.split('x');
	return Math.ceil(w * scale) + 'x' + Math.ceil(h * scale);
}
