const maxInt = 2147483647;
const base = 36;
const tMin = 1;
const tMax = 26;
const initialBias = 72;
const initialN = 128;
const delimiter = '-';
const skew = 38;
const damp = 700;
const baseMinusTMin = base - tMin;
const regexPunycode = /xn--/;
const regexSeparators = /[\x2E\u3002\uFF0E\uFF61]/g; // RFC 3490 separators

const basicToDigit = (codePoint) => {
	if (codePoint >= 0x30 && codePoint < 0x3a) {
		return 26 + (codePoint - 0x30);
	}
	if (codePoint >= 0x41 && codePoint < 0x5b) {
		return codePoint - 0x41;
	}
	if (codePoint >= 0x61 && codePoint < 0x7b) {
		return codePoint - 0x61;
	}
	return base;
};

const adapt = (delta, numPoints, firstTime) => {
	let k = 0;
	delta = firstTime ? Math.floor(delta / damp) : delta >> 1;
	delta += Math.floor(delta / numPoints);
	for (; /* no initialization */ delta > (baseMinusTMin * tMax) >> 1; k += base) {
		delta = Math.floor(delta / baseMinusTMin);
	}

	return Math.floor(k + ((baseMinusTMin + 1) * delta) / (delta + skew));
};

const decode = (input: string): string => {
	const output = [];
	const inputLength = input.length;
	let i = 0;
	let n = initialN;
	let bias = initialBias;
	let basic = input.lastIndexOf(delimiter);
	if (basic < 0) {
		basic = 0;
	}
	for (let j = 0; j < basic; ++j) {
		if (input.charCodeAt(j) >= 0x80) {
			return;
		}
		output.push(input.charCodeAt(j));
	}
	for (let index = basic > 0 ? basic + 1 : 0; index < inputLength; ) {
		const oldi = i;
		for (let w = 1, k = base /* no condition */; ; k += base) {
			if (index >= inputLength) {
				return;
			}
			const digit = basicToDigit(input.charCodeAt(index++));
			if (digit >= base) {
				return;
			}
			if (digit > Math.floor((maxInt - i) / w)) {
				return;
			}
			i += digit * w;
			const t = k <= bias ? tMin : k >= bias + tMax ? tMax : k - bias;
			if (digit < t) {
				break;
			}
			const baseMinusT = base - t;
			if (w > Math.floor(maxInt / baseMinusT)) {
				return;
			}
			w *= baseMinusT;
		}
		const out = output.length + 1;
		bias = adapt(i - oldi, out, oldi === 0);
		if (Math.floor(i / out) > maxInt - n) {
			return;
		}
		n += Math.floor(i / out);
		i %= out;
		output.splice(i++, 0, n);
	}

	return String.fromCodePoint(...output);
};

const mapDomain = (domain: string, callback) => {
	const parts = domain.split('@');
	let mailbox = '';
	if (parts.length > 1) {
		mailbox = parts[0] + '@';
		domain = parts[1];
	}

	domain = domain.replace(regexSeparators, '\x2E');
	const labels = domain.split('.');
	const encoded = map(labels, callback).join('.');
	return mailbox + encoded;
};

function map(array, callback) {
	const result = [];
	let length = array.length;
	while (length--) {
		result[length] = callback(array[length]);
	}
	return result;
}

export const toUnicode = (input: string) => {
	if (!regexPunycode.test(input)) return input;

	return input.replace(/(xn--.*?)(\/|$|\s)/gm, (_, m1, m2) => {
		return (
			mapDomain(m1, (string: string) => {
				return regexPunycode.test(string) ? decode(string.slice(4).toLowerCase()) : string;
			}) + m2
		);
	});
};
