import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useLayoutOptions } from 'modules/Layout';
import { useLocation } from 'react-router-dom';
import { authStore } from 'stores';
import { Link, Button, Stub } from 'vgui';

const AuthAlertWidget = () => {
	const { pathname } = useLocation();

	useLayoutOptions({
		title: 'Страница защищена',
		hideAside: true,
	});

	return (
		<Stub icon={<UserCircleIcon />}>
			<div className="mb-4">Для просмотра страницы необходимо авторизоваться</div>
			<Link to={'/auth'} state={{ from: pathname }}>
				<Button stopOther={false} size="big">
					Войти прямо сейчас
				</Button>
			</Link>
		</Stub>
	);
};

const useAuth = () => {
	const isAuth = authStore.isAuth();
	const stub = !isAuth && <AuthAlertWidget />;
	const user = isAuth ? authStore.user : null;
	const location = authStore.location;

	return { isAuth, user, location, authStore, stub };
};

export default useAuth;
