import { METRIX_URL } from 'constants/Config';

class Metrix {
	url = METRIX_URL;
	debug = false;
	identity = null;
	events = [];
	cache = [];

	init(id) {
		this.identity = id;
		this.send();
		window.addEventListener('beforeunload', () => {
			this.send();
		});
	}

	hash = (str, seed = 0) => {
		let h1 = 0xdeadbeef ^ seed,
			h2 = 0x41c6ce57 ^ seed;

		for (let i = 0, ch; i < str.length; i++) {
			ch = str.charCodeAt(i);
			h1 = Math.imul(h1 ^ ch, 2654435761);
			h2 = Math.imul(h2 ^ ch, 1597334677);
		}

		h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
		h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

		return 4294967296 * (2097151 & h2) + (h1 >>> 0);
	};

	uniq = (item) => {
		const hash = this.hash(JSON.stringify(item));
		if (this.cache.includes(hash)) return false;

		this.cache.push(hash);
		return true;
	};

	push(action, params) {
		// if (!this.uniq({ action, ...params })) return;

		const p = {
			a: action,
			ts: new Date().getTime(),
			...params,
		};

		// console.info(p);

		this.events.push(p);
		if (this.events.length > 100) this.send();
	}

	send() {
		if (this.timeout) clearTimeout(this.timeout);

		if (this.events && this.events.length > 0) {
			const buf = this.events;
			this.events = [];

			this.post({
				dt: new Date().getTime(),
				idx: this.idx,
				identity: this.identity,
				items: buf,
			}).catch(() => {
				this.events.concat(buf);
			});
		}

		this.timeout = setTimeout(() => this.send(), 5000);
	}

	post(data) {
		return new Promise((resolve, reject) => {
			if (navigator.sendBeacon) {
				const fd = new FormData();
				fd.append('data', JSON.stringify(data));
				if (navigator.sendBeacon(this.url, fd)) return resolve(true);
			}

			const xhr = new XMLHttpRequest();
			xhr.open('POST', this.url, true);
			xhr.onload = function () {
				if (xhr.status >= 200 && xhr.status < 300) {
					resolve(xhr.response);
				} else {
					reject({
						status: xhr.status,
						statusText: xhr.statusText,
					});
				}
			};
			xhr.onerror = function () {
				reject({
					status: xhr.status,
					statusText: xhr.statusText,
				});
			};
			xhr.setRequestHeader('Content-Type', 'application/json');
			xhr.send(JSON.stringify(data));
		});
	}
}

export default new Metrix();
