import PostItem from 'modules/Post/components/PostItem';
import { Placeholder } from 'vgui';

export const AttachmentRepost = ({ items }) => {
	if (!items) return null;
	const { context } = items[0];

	if (context.placeholder) return <Placeholder>{context.placeholder}</Placeholder>;

	return <PostItem {...context} repost={true} />;
};
