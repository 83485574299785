import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button, Stack, Link } from 'vgui';

export const ErrorPretty = ({
	icon = <ExclamationTriangleIcon />,
	title,
	info,
	code,
	description,
	children,
	backText = 'Вернуться назад',
}) => (
	<Stack className="h-screen -mt-16 items-center justify-center px-4 text-center">
		<span className="w-32 mb-4">{icon}</span>
		{title && <h1>{title}</h1>}
		{info && <div className="bg-gray-300 bg-opacity:20 px-4 py-2 mb-8 rounded-md text-muted">{info}</div>}
		{description && <div>{description}</div>}
		{children}
		<Link to={code !== 410 && window.history.state && window.history.state.idx > 0 ? -1 : '/'} className="mt-8">
			<Button>{backText}</Button>
		</Link>
	</Stack>
);

export default ErrorPretty;
