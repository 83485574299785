import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { relative } from 'helpers/utils';
import GroupHeader from 'modules/Group/components/GroupHeader';
import ProfileHeader from 'modules/Profile/components/ProfileHeader';
import { HStack, Link } from 'vgui';

const PostHeader = ({ children, ...props }) => {
	const { privacy, edit, status, author, anon } = props;
	const Component = props.type === 'user' ? ProfileHeader : GroupHeader;

	return (
		<Component {...props}>
			<HStack className={'text-muted text-xs gap-1 truncate nowrap'}>
				{status && <span className={'text-white text-xs bg-warning px-2 rounded'}>{status}</span>}
				{['private', 'closed'].includes(privacy) && (
					<LockClosedIcon
						className={'w-2.5 h-2.5 mb-[1px] inline align-baseline shrink-0'}
						data-bs-toggle="tooltip"
						title="Приватный доступ"
					/>
				)}
				{edit ? 'В работе' : children}
				{author && author.title && author.title !== 'DELETED' && (
					<>
						<span>&sdot;</span>
						{anon && (
							<EyeSlashIcon
								className="w-2.5 h-2.5 inline align-baseline shrink-0"
								data-bs-toggle="tooltip"
								title="Анонимно"
							/>
						)}
						{author.id ? (
							<Link to={relative(author.url)} className="link !text-muted">
								{author.title}
							</Link>
						) : (
							<span>{author.title}</span>
						)}
					</>
				)}
			</HStack>
		</Component>
	);
};

export default PostHeader;
