import { ShareIcon } from '@heroicons/react/24/outline';
import { useModal } from 'react-modal-hook';
import { Button } from 'vgui';

import ShareModal from './ShareModal';

const ShareButton = ({ url, disabled = false }) => {
	const [showModal, hideModal] = useModal(() => <ShareModal onClose={hideModal} url={url} />);

	return (
		<Button round size="small" variant="secondary" onClick={showModal} title="Поделиться" disabled={disabled}>
			<ShareIcon className={'w-4'} />
		</Button>
	);
};

export default ShareButton;
