import { makeAutoObservable, observable } from 'mobx';

class FilterStore {
	filters = observable.map({});

	put(key, filter) {
		this.filters.set(key, filter);
	}

	get(key) {
		return this.filters.get(key) || null;
	}

	delete(key) {
		this.filters.delete(key);
	}
}

const filterStore = makeAutoObservable(new FilterStore());
export default filterStore;
export { FilterStore };
