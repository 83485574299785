import { makeAutoObservable, observable } from 'mobx';

const def = {
	className: null,
	aside: null,
	nav: null,
	hideAppBar: false,
	hideAside: false,
	hideNavigator: false,
	showBottomBar: false,
	minimize: false,
};

class LayoutStore {
	theme = 'light';
	size = 'sm';
	aside = null;
	nav = null;
	status = null;
	countChat = 0;
	countNotice = 0;
	hideNavigator = true;
	hideAppBar = true;
	hideAside = true;

	header = observable.object({});

	setOptions(props) {
		for (const [k, v] of Object.entries({ ...def, ...props })) this[k] = v;
	}

	setCountChat = (value) => {
		this.countChat = value;
	};

	setHeader = (data) => {
		this.header = data;
	};

	setCountNotice = (value) => {
		this.countNotice = value;
	};

	getHeader = () => {
		return this.header;
	};
}

const layoutStore = makeAutoObservable(new LayoutStore());
export default layoutStore;
export { LayoutStore };
