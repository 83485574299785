import { lazy } from 'react';
import { useModal } from 'react-modal-hook';

const AuthModal = lazy(() => import('../modals/AuthModal'));

export default function useAuthModal(variant = null) {
	const [showModal, hideModal] = useModal(() => <AuthModal onHide={hideModal} variant={variant} />);

	return showModal;
}
