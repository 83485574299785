import { useEffect } from 'react';

const targets = new WeakMap();
const observers = new Map();

function handleIntersections(entries) {
	entries.forEach((entry) => {
		if (targets.has(entry.target)) {
			const { type, callback } = targets.get(entry.target);

			if (entry.isIntersecting || entry.intersectionRatio > 0.5) {
				observers.get(type)?.unobserve(entry.target);
				targets.delete(entry.target);
				callback(entry);
			}
		}
	});
}

function getIntersectionObserver(type, params) {
	if (!observers.has(type)) observers.set(type, new IntersectionObserver(handleIntersections, params));
	return observers.get(type);
}

export function useIntersection({
	ref,
	type = 'custom',
	callback = () => {},
	params = {
		rootMargin: '100px',
		threshold: '0.15',
	},
}) {
	useEffect(() => {
		const target = ref?.current;
		if (!target) return;

		const observer = getIntersectionObserver(type, params);
		targets.set(target, {
			type,
			callback,
		});
		observer.observe(target);

		return () => {
			targets.delete(target);
			observer.unobserve(target);
		};
	}, [ref, type, params, callback]);
}
