import { Provider } from 'mobx-react';
import Analytics from 'modules/Analytics';
import { routes } from 'navigation';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import useBootstrap from './hooks/useBootstrap';
import * as stores from './stores';

const router = createBrowserRouter(routes);

const App = () => {
	const isBootstrapComplete = useBootstrap();

	return (
		isBootstrapComplete && (
			<Provider {...stores}>
				<Analytics />
				<RouterProvider router={router} />
			</Provider>
		)
	);
};

export default App;
