import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

const DragArea = ({ onSelect, placeholder = 'Перетащите файлы сюда', dragAreaTarget }) => {
	const ref = useRef(null);
	const [dragged, setDragged] = useState(false);
	const [dragMode, setDragMode] = useState(false);

	useEffect(() => {
		if (dragAreaTarget && dragMode) {
			const target = document.getElementById(dragAreaTarget).getBoundingClientRect();
			ref.current.style.width = target.width + 'px';
			ref.current.style.height = target.height + 'px';
			ref.current.style.left = target.left + 'px';
			ref.current.style.top = target.top + 'px';
		}
	}, [dragAreaTarget, dragMode]);

	const handleDragEnter = (e) => {
		e.dataTransfer.dropEffect = 'copy';
		setDragged(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		setDragged(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		onSelect(e.dataTransfer.files);
		setDragged(false);
		setDragMode(false);
	};

	useEffect(() => {
		const dragStart = (e) => {
			e.preventDefault();
			setDragMode(true);
		};

		const dragOver = (e) => {
			e.preventDefault();
		};

		const dragEnd = (e) => {
			e.preventDefault();

			if ((!e.relatedTarget && e.type === 'dragleave') || e.type !== 'dragleave') {
				setDragMode(false);
			}
		};

		window.addEventListener('dragenter', dragStart, true);
		window.addEventListener('dragleave', dragEnd, true);
		window.addEventListener('dragend', dragEnd, true);
		window.addEventListener('dragover', dragOver, true);

		return () => {
			window.removeEventListener('dragenter', dragStart, true);
			window.removeEventListener('dragleave', dragEnd, true);
			window.removeEventListener('dragend', dragEnd, true);
			window.removeEventListener('dragover', dragOver, true);

			setDragMode(false);
		};
	}, []);

	if (!dragMode) return;

	return (
		<div
			ref={ref}
			onDragLeave={handleDragLeave}
			onDragEnter={handleDragEnter}
			onDrop={handleDrop}
			className={`bg-white z-[3] ${dragAreaTarget ? 'fixed p-2' : 'absolute w-full h-full'}`}
		>
			<div
				onDragEnter={handleDragEnter}
				onDrop={handleDrop}
				className={classNames(
					'flex transition-colors justify-center items-center pointer-events-none border-2 border-dashed rounded-lg w-full h-full text-md font-semibold',
					dragged ? 'border-green-600 text-green-600' : 'border-muted text-muted'
				)}
			>
				{placeholder}
			</div>
		</div>
	);
};

export default DragArea;
