import { ArrowUturnRightIcon } from '@heroicons/react/24/outline';
import { relative } from 'helpers/utils';
import GroupIcon from 'modules/Group/components/GroupIcon';
import ProfileIcon from 'modules/Profile/components/ProfileIcon';
import { HStack, DatePretty, Link } from 'vgui';

const RepostHeader = ({ url, dt_publish, decoration }) => {
	const { type, image, nick, title } = decoration.header;

	return (
		<HStack className="text-sm self-start gap-1">
			<ArrowUturnRightIcon className="w-4 h-4" />
			<Link to={relative(url)} className="font-medium text-sm hstack items-center gap-1 max-w-[calc(100vw-55px)]">
				{type === 'user' ? (
					<ProfileIcon url={image} alt={nick} size="xxs" />
				) : (
					<GroupIcon url={image} alt={title} size="xxs" />
				)}
				<span className="text-sm truncate mx-1">{title}</span>
				<DatePretty value={dt_publish} className="mt-[2px]" />
			</Link>
		</HStack>
	);
};

export default RepostHeader;
