import { useEffect, useLayoutEffect, useState } from 'react';
import { localStore } from 'stores/storage';

const mql = window.matchMedia('(prefers-color-scheme: dark)');

const setDarkTheme = (val) => document.documentElement.setAttribute('data-theme', val ? 'dark' : 'light');

export default function useTheme() {
	const [theme, setTheme] = useState('dark');

	useLayoutEffect(() => {
		if (theme) setDarkTheme(theme === 'auto' ? mql.matches : theme === 'dark');
	}, [theme]);

	useLayoutEffect(() => {
		const val = localStore.getItem('theme');
		setTheme(val || 'auto');
	}, []);

	useEffect(() => {
		const listener = (e) => {
			if (theme === 'auto') setDarkTheme(e.matches);
		};

		mql.addEventListener('change', listener);
		return () => mql.removeEventListener('change', listener);
	}, [theme]);

	const switchTheme = () => {
		const val = theme === 'auto' ? 'dark' : theme === 'dark' ? 'light' : 'auto';
		localStore.setItem('theme', val);
		setTheme(val);
	};

	return { theme, switchTheme };
}
