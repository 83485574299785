import { useState, useEffect, useRef, useCallback } from 'react';

export default function useOutsideClick(initialIsVisible, initialRef) {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const innerRef = useRef(null);
	const ref = initialRef || innerRef;

	const handleClickOutside = useCallback(
		(event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsComponentVisible(false);
			}
		},
		[ref]
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, [handleClickOutside]);

	return { ref, isComponentVisible, setIsComponentVisible };
}
