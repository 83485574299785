import defaultImage from 'assets/images/nogroup.svg';
import classNames from 'classnames';
import { Avatar } from 'vgui';

const GroupIcon = ({ url, alt, size = 'sm', className, onClick = () => {} }) => (
	<Avatar
		url={url ? url : defaultImage}
		alt={alt}
		size={size}
		rounded
		className={classNames('object-cover hover:cursor-pointer', className)}
		onClick={onClick}
	/>
);

export default GroupIcon;
