import Centrifuge from 'centrifuge';
import { CENT_URL } from 'constants/Config';

class Cent {
	client = null;

	connect = (token, userId, handler) => {
		this.client = new Centrifuge(CENT_URL);
		this.client.setToken(token);
		this.client.subscribe('vg:inbox#' + userId, handler);
		this.client.connect();
	};

	disconnect = () => {
		if (this.isConnected()) this.client.disconnect();
	};

	isConnected = () => {
		return this.client?._status === 'connecting';
	};
}

export default new Cent();
