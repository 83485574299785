import { relative } from 'helpers/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { Photo } from 'vgui';

const renderVacancy = ({ context }) => {
	const { job_title, image, salary_formatted, address, requirements, url } = context;

	return (
		<div className="attachment-vacancy w-full max-w-md">
			<p className="mark">Вакансия</p>
			<Link to={relative(url)}>
				<div className="body">
					<h2>{job_title}</h2>
					<div>{salary_formatted}</div>
					<p>{address}</p>
					<p className="text-sm text-gray-400">{requirements}</p>
					{image && <Photo url={image} width={800} height={400} placeholder alt={job_title || 'img'} />}
				</div>
			</Link>
		</div>
	);
};

export const AttachmentVacancy = ({ items }) => {
	if (!items) return;
	return renderVacancy(items[0]);
};
