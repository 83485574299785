import BadIcon from 'assets/images/reaction-bad.png';
import ClownIcon from 'assets/images/reaction-clown.png';
import HeartIcon from 'assets/images/reaction-heart.png';
import LikeIcon from 'assets/images/reaction-like.png';
import LolIcon from 'assets/images/reaction-lol.png';
import SadIcon from 'assets/images/reaction-sad.png';
import VomitIcon from 'assets/images/reaction-vomit.png';
import WowIcon from 'assets/images/reaction-wow.png';

const icons = {
	like: LikeIcon,
	heart: HeartIcon,
	wow: WowIcon,
	lol: LolIcon,
	sad: SadIcon,
	bad: BadIcon,
	clown: ClownIcon,
	vomit: VomitIcon,
};

const ReactionPic = ({ name, className, onClick = () => {} }) => {
	const icon = icons[name] || null;
	return icon && <img className={className} src={icon} alt={name} onClick={onClick} />;
};

export default ReactionPic;
