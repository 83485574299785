import { useEffect, useState } from 'react';
import api from 'services/api';

const fetcher = async (url, ...args) => {
	return api.get(url, ...args).then(({ data }) => {
		delete data['_meta'];
		delete data['_links'];
		return Promise.resolve(data);
	});
};

export default function useQuery(url) {
	const [data, setData] = useState(null);
	const [isLoading, setLoading] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (!url) return;

		setLoading(true);
		fetcher(url)
			.then((data) => setData(data))
			.catch((error) => {
				setError(error);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [url]);

	return { api, data, error, isLoading };
}
