import { POSTS } from 'constants/Types';
import { toQueryString } from 'helpers';
import { get } from 'helpers/utils';
import { toJS } from 'mobx';
import { normalize } from 'normalizr';
import { postSchema } from 'schemas';
import api from 'services/api';
import { entityStore, paginateStore, postStore, requestStore } from 'stores';

export function fetchPosts(filter, clear = false) {
	const requestType = POSTS + '_' + filter.key;

	const nextHref = paginateStore.getLinkByType(filter.key);
	if (nextHref === undefined && !clear) {
		requestStore.setRequestInProcess(requestType, false);
		return;
	}

	if (requestStore.getRequestByType(requestType)) return Promise.resolve();
	requestStore.setRequestInProcess(requestType, true);

	const prefix = filter.key.split('_')[0];

	let params = {};
	if (prefix !== 'search' && filter?.type !== 'bookmarks') {
		params['scope'] = prefix === 'feed' || prefix === 'city' ? 'index' : 'list';
	}

	let route = '/v1/post';
	switch (prefix) {
		case 'myfeed':
			route = '/v1/post?type=feed';
			break;
		case 'user':
			if (filter.userId) params['author_id'] = filter.userId;
			break;
		case 'wall':
			if (filter.wall_id) params['wall_id'] = filter.wall_id;
			break;
		case 'video':
			if (filter.groupId) params['group_id'] = filter.groupId;
			params['has_video'] = 1;
			break;
		case 'search':
			if (filter.q) params['q'] = filter.q;
			if (filter.city) params['city'] = filter.city;
			break;
		case 'offer':
			params['group_id'] = filter.groupId;
			params['type'] = 'offer';
			break;
		default:
			if (filter.groupId) params['group_id'] = filter.groupId;
	}

	params['trending'] = filter.trending;
	params['main'] = filter.main;
	if (filter.groups) params['groups'] = filter.groups.join(',');

	if (filter?.type === 'bookmarks') {
		route = '/v1/post';
		params.type = filter.type;
	}

	const initHref = route + '?' + toQueryString(params);
	const url = (clear ? null : nextHref) || initHref;

	return api
		.get(url, clear)
		.then(({ data }) => {
			if (clear) postStore.clear(filter.key);
			const normalized = normalize(data, { items: [postSchema] });
			entityStore.mergeEntities(POSTS, normalized.entities.posts);
			postStore.append(filter.key, normalized.result.items || []);
			paginateStore.setPaginateLink(filter.key, get(data, '_links.next.href', null));
			paginateStore.setCounters(filter.key, {
				pageCount: parseInt(get(data, '_meta.pageCount', 0)),
				totalCount: parseInt(get(data, '_meta.totalCount', 0)),
			});
			requestStore.setRequestInProcess(requestType, false);
			return Promise.resolve(normalized.result.items);
		})
		.catch((error) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export function getPost(id, key = null) {
	const requestType = POSTS + '_' + id;

	if (requestStore.getRequestByType(requestType)) return Promise.resolve();
	requestStore.setRequestInProcess(requestType, true);

	const url = `/v1/post/${id}` + (key ? `?key=${key}` : '');
	return api
		.get(url)
		.then(({ data }) => {
			delete data['_meta'];
			const normalized = normalize(data, postSchema);
			entityStore.mergeEntities(POSTS, normalized.entities.posts);
			requestStore.setRequestInProcess(requestType, false);
			return Promise.resolve(normalized.result);
		})
		.catch((error) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export function fetchPostsRelated(id) {
	const requestType = POSTS + '_related_' + id;

	if (requestStore.getRequestByType(requestType)) return Promise.reject();
	requestStore.setRequestInProcess(requestType, true);

	const url = `/v1/post/related?id=${id}`;
	return api
		.get(url)
		.then(({ data }) => {
			delete data['_meta'];
			const normalized = normalize(data, { items: [postSchema] });
			entityStore.mergeEntities(POSTS, normalized.entities.posts);
			postStore.append(requestType, normalized.result.items);
			requestStore.setRequestInProcess(requestType, false);
			return Promise.resolve(normalized.result);
		})
		.catch((error) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export function fetchPostsBreaking(filter) {
	const requestType = filter.key;

	if (requestStore.getRequestByType(requestType)) return Promise.resolve();
	requestStore.setRequestInProcess(requestType, true);

	let url = `/v1/post?fields=id,url,header,dt_publish&scope=widget&limit=5&city=${filter.geo_id}&main=1`;

	return api
		.get(url, true)
		.then(({ data }) => {
			postStore.clear(requestType);
			delete data['_links'];
			delete data['_meta'];
			const normalized = normalize(data, { items: [postSchema] });

			postStore.append(requestType, normalized.result.items);
			entityStore.mergeEntities(requestType, normalized.entities.posts);

			requestStore.setRequestInProcess(requestType, false);
			return Promise.resolve(normalized.entities.posts);
		})
		.catch((error) => {
			requestStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export function wipePostStore() {
	return postStore.wipe();
}

export function deletePost(id, action = 'delete') {
	const item = toJS(entityStore.getEntitiesByKey(POSTS).get(id));
	const val = action === 'delete';
	const old = item.deleted;
	if (item && val === old) Promise.resolve();

	item.deleted = val;
	entityStore.syncEntities(item, POSTS);

	return api
		.PATCH(`/v1/post/${id}`, {
			action: action,
		})
		.catch((e) => {
			item.deleted = old;
			entityStore.syncEntities(item, POSTS);
			return Promise.reject(e);
		});
}

export function patchPost(id, data) {
	requestStore.setRequestInProcess(POSTS, true);

	return api
		.patch(`/v1/post/${id}`, data)
		.then(({ data }) => {
			delete data['_meta'];
			entityStore.updateEntity(id, POSTS, { ...data });
			requestStore.setRequestInProcess(POSTS, false);
			return Promise.resolve();
		})
		.catch((response) => {
			requestStore.setRequestInProcess(POSTS, false);
			return Promise.reject(response.data);
		});
}

export function actionPost(action, id) {
	patchPost(id, { action: action });

	return new Promise((resolve) => {
		let item = toJS(entityStore.getEntitiesByKey(POSTS).get(id));

		if (item) {
			switch (action) {
				case 'mark':
					item.params.bookmark = true;
					postStore.prepend('bookmarks_post', [id]);
					break;
				case 'unmark':
					item.params.bookmark = false;
					postStore.remove('bookmarks_post', id);
					break;
				case 'delete':
					item.deleted = true;
					break;
				case 'restore':
					item.deleted = false;
					break;
				// case 'accept':
				// 	item.params.offer = false;
				// 	item.params.draft = true;
				// 	item.dt_publish = new Date();
				// 	postStore.remove('offer_' + item.group_id, id);
				// 	break;
				default:
					break;
			}
		}

		entityStore.updateEntity(id, POSTS, { ...item });
		return resolve(item);
	});
}

/* experimental request template */
function requestTemplate(method, type, url, data, callback) {
	if (requestStore.getRequestByType(type)) return Promise.resolve();
	requestStore.setRequestInProcess(type, true);

	return api[method](url, data)
		.then(({ data }) => {
			requestStore.setRequestInProcess(type, false);

			if (!data) return Promise.resolve();

			const { _links, _meta, ...main } = data;
			return Promise.resolve(callback(main, _meta, _links));
		})
		.catch((error) => {
			requestStore.setRequestInProcess(type, false);
			return Promise.reject(error);
		});
}

// function getRequest(type, url, data, callback) {
// 	return requestTemplate('get', type, url, data, callback);
// }

function postRequest(type, url, data, callback) {
	return requestTemplate('post', type, url, data, callback);
}

// function deleteRequest(type, url, data, callback) {
// 	return requestTemplate('delete', type, url, data, callback);
// }

function patchRequest(type, url, data, callback) {
	return requestTemplate('patch', type, url, data, callback);
}
/* experimental request template */

export function savePost(post) {
	return (post.id ? patchRequest : postRequest)(POSTS, `/v1/post` + (post.id ? `/${post.id}` : ''), post, (data) => {
		const normalized = normalize(data, postSchema);
		entityStore.mergeEntities(POSTS, normalized.entities.posts);
		return data;
	});
}

export function repost(group_id, repost_id, text) {
	return postRequest(
		POSTS,
		'/v1/post',
		{
			group_id,
			repost_id,
			text,
		},
		(data) => {
			const normalized = normalize(data, postSchema);

			entityStore.mergeEntities(POSTS, normalized.entities.posts);

			return data;
		}
	);
}
