import { makeAutoObservable, observable } from 'mobx';

class AuthStore {
	user = observable.object({});
	location = observable.object({});

	setUser = (data) => {
		this.user = data;
	};

	getUser = () => {
		return this.user;
	};

	setLocation = (location) => {
		this.location = location;
	};

	isAuth = () => {
		return !!this.user?.id;
	};

	wipe = () => {
		this.setUser(null);
	};
}

const authStore = makeAutoObservable(new AuthStore());
export default authStore;
export { AuthStore };
