import { repost } from 'actions/post';
import useQuery from 'hooks/useQuery';
import useToaster from 'hooks/useToaster';
import useAuth from 'modules/Auth/hooks/useAuth';
import { useState } from 'react';
import {
	OKShareButton,
	TelegramShareButton,
	ViberShareButton,
	VKShareButton,
	WhatsappShareButton,
	OKIcon,
	TelegramIcon,
	ViberIcon,
	VKIcon,
	WhatsappIcon,
} from 'react-share';
import { Button, HStack, Stack, Accordion, TextArea, SearchBox, InputNew, AdaptiveModal } from 'vgui';

const rxRepost = /.*\/post(\d+)$/;

const SocialButtons = ({ url, ...props }) => (
	<HStack className="gap-3 flex-wrap justify-center">
		<VKShareButton url={url}>
			<VKIcon {...props} />
		</VKShareButton>
		<OKShareButton url={url}>
			<OKIcon {...props} />
		</OKShareButton>
		<TelegramShareButton url={url}>
			<TelegramIcon {...props} />
		</TelegramShareButton>
		<WhatsappShareButton url={url}>
			<WhatsappIcon {...props} />
		</WhatsappShareButton>
		<ViberShareButton url={url}>
			<ViberIcon {...props} />
		</ViberShareButton>
	</HStack>
);

const RepostForm = ({ url, onClose }) => {
	const [selectedGroup, setGroup] = useState();
	const [text, setText] = useState('');

	const { toastPromise } = useToaster();

	const { data, isLoading } = useQuery(`/v1/group/?type=admin&fields=id,header&limit=100`);

	const handleRepost = () => {
		const repostId = url.replace(rxRepost, '$1');
		toastPromise(repost(selectedGroup.id, repostId, text), {
			loading: 'Публикация',
			success: () => {
				return 'Запись опубликована';
			},
		});
		onClose();
	};

	return (
		<Stack>
			<SearchBox
				placeholder="Выберите группу"
				onChange={setGroup}
				loading={isLoading}
				options={!data ? [] : data.items?.map(({ id, header }) => ({ id, value: header }))}
			/>

			<TextArea placeholder="Комментарий..." onChange={(e) => setText(e.target.value)} />
			<Button size="small" className={'grow'} onClick={handleRepost} disabled={!selectedGroup}>
				Поделиться
			</Button>
		</Stack>
	);
};

const ShareModal = ({ onClose, url }) => {
	const { isAuth } = useAuth();
	const enabledRepost = isAuth && rxRepost.test(url);

	return (
		<AdaptiveModal show={true} onHide={onClose}>
			<AdaptiveModal.Header onHide={onClose}>Поделиться</AdaptiveModal.Header>
			<AdaptiveModal.Body>
				<Accordion defaultIndex={0}>
					<Accordion.Item title="Соцсети">
						<SocialButtons url={url} size={48} round={true} />
					</Accordion.Item>
					{enabledRepost && (
						<Accordion.Item title="Репост в группе">
							<RepostForm {...{ url, onClose }} />
						</Accordion.Item>
					)}
					<Accordion.Item title="Прямая ссылка">
						<InputNew value={url} readOnly onClick={(e) => e.target.select()} className="text-center" />
					</Accordion.Item>
				</Accordion>
			</AdaptiveModal.Body>
		</AdaptiveModal>
	);
};

export default ShareModal;
