import useTheme from '../hooks/useTheme';
import { Pressable } from 'vgui';

import { LightBulbIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';

const themes = {
	auto: {
		icon: <LightBulbIcon />,
		hint: 'Системная',
	},
	light: {
		icon: <SunIcon />,
		hint: 'Светлая',
	},
	dark: {
		icon: <MoonIcon />,
		hint: 'Тёмная',
	},
};

export const ThemeButton = () => {
	const { theme, switchTheme } = useTheme();

	return (
		<Pressable className="w-6 hover:text-primary" title={themes[theme].hint} onClick={switchTheme}>
			{themes[theme].icon}
		</Pressable>
	);
};
