import { disconnectCent } from 'actions/auth/cent';
import { FOLLOW, PROFILE_VIEW } from 'constants/Types';
import { get } from 'helpers/utils';
import { toJS } from 'mobx';
import { normalize } from 'normalizr';
import { followSchema, profileSchema } from 'schemas';
import api from 'services/api';
import { entityStore, requestStore, paginateStore, profileStore, authStore } from 'stores';
import { localStore } from 'stores/storage';

const cache = new Map();

export function getProfile(alias) {
	alias = alias === 'profile' ? authStore.user.id : `${alias}`.replace('@', '');

	if (cache.has(alias)) {
		const id = cache.get(alias);
		const stored = entityStore.getEntitiesByKey(PROFILE_VIEW).get(id);
		if (stored) return Promise.resolve(stored);
	}

	return api.GET(`/v1/profile/${alias}`).then((item) => {
		cache.set(alias, item.id);
		const normalized = normalize(item, profileSchema);
		entityStore.mergeEntities(PROFILE_VIEW, normalized.entities.profile);
		return Promise.resolve(item);
	});
}

export function updateProfile(id, data) {
	return api.PATCH(`/v1/profile/${id}`, data).then((item) => {
		const normalized = normalize(item, profileSchema);
		entityStore.mergeEntities(PROFILE_VIEW, normalized.entities.profile);
		return Promise.resolve(item);
	});
}

export function updateMe(data) {
	const user = authStore.getUser();
	if (!user) return Promise.reject();

	return updateProfile(user.id, data).then((item) => {
		authStore.setUser(item);
		return Promise.resolve(item);
	});
}

export function deleteProfile(id) {
	return api.delete(`/v1/profile/${id}`).then(() => {
		disconnectCent();
		try {
			localStore.removeItem('token');
		} catch (e) {}
		return Promise.resolve();
	});
}

export function sendFollow(item, follow = true) {
	item = toJS(item);
	item.follow = follow;
	if (item.counters) item.counters.followers = Number(item.counters.followers) + (follow ? 1 : -1);
	entityStore.mergeEntities(PROFILE_VIEW, [item]);

	return api.POST('/v1/follow', { follow_id: item.id, ac: follow ? 'follow' : 'unfollow' });
}

export function fetchFollowers(filter, clear = false) {
	const requestType = filter.key;
	const nextHref = paginateStore.getLinkByType(requestType);
	if (nextHref === undefined && !clear) {
		requestStore.setRequestInProcess(requestType, false);
		return Promise.resolve();
	}
	if (requestStore.getRequestByType(requestType)) return Promise.resolve();
	requestStore.setRequestInProcess(requestType, true);

	const initHref = `/v1/follow?${filter?.params}`;
	const url = clear ? initHref : nextHref;

	return api
		.get(url)
		.then(({ data }) => {
			if (clear) profileStore.clear(requestType);
			const normalized = normalize(data, { items: [followSchema] });
			entityStore.mergeEntities(FOLLOW, normalized.entities.follow);
			profileStore.append(filter.key, normalized.result.items || []);
			paginateStore.setCounters(requestType);
			paginateStore.setPaginateLink(requestType, get(data, '_links.next.href'));
			return Promise.resolve();
		})
		.catch((e) => {
			return Promise.reject(e.response?.data || e.response);
		})
		.finally(() => {
			requestStore.setRequestInProcess(requestType, false);
		});
}
