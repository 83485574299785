import { actionGroup } from 'actions/group';
import { getPost, deletePost, actionPost } from 'actions/post';
import { GROUPS_WITH_ADV, TITLE_SUFFIX } from 'constants/Config';
import { POSTS } from 'constants/Types';
import { setTitle } from 'helpers/utils';
import useToaster from 'hooks/useToaster';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Adv } from 'modules/Adv';
import { Reach } from 'modules/Analytics';
import useAuth from 'modules/Auth/hooks/useAuth';
import useAuthModal from 'modules/Auth/hooks/useAuthModal';
import CommentListContainer from 'modules/Comment/containers/CommentListContainer';
import PostItem from 'modules/Post/components/PostItem';
import PostView from 'modules/Post/components/PostView';
import { Suspense, lazy, memo, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Error } from 'modules/Error';
import { Button, Spinner, Stack } from 'vgui';

const PostListRelatedContainer = lazy(() => import('./PostListRelatedContainer'));

const NoAuthBlock = () => {
	const location = useLocation();
	return (
		<Stack id="comments" className="items-center border-t border-dotted pt-4 pb-2">
			<p className="text-sm text-center">
				Используйте мобильное приложение, чтобы&nbsp;читать&nbsp;и&nbsp;добавлять&nbsp;комментарии
			</p>
			<Link to={'/install'} state={{ from: location.pathname }}>
				<Button stopOther={false}>{'Открыть в приложении'}</Button>
			</Link>
		</Stack>
	);
};

const Footer = memo(
	({ item, userId }) => {
		return !userId ? (
			<NoAuthBlock />
		) : item.params['no_comments'] ? (
			<span className="text-sm">Автор ограничил возможность комментировать пост</span>
		) : !item.permissions?.can_post_comment ? (
			<span></span>
		) : (
			<CommentListContainer filter={{ postId: item.id, key: 'post_comment_' + item.id }} />
		);
	},
	(a, b) => a.userId === b.userId && a.item.id === b.item.id,
);

const PostViewContainer = ({ id, modal = false, onLoad = () => {}, onClose, entityStore }) => {
	const [error, setError] = useState(null);
	const item = entityStore.getEntitiesByKey(POSTS).get(id);
	const [searchParams] = useSearchParams();
	const key = searchParams.get('key');
	const editMode = searchParams.get('mode');
	const { toastPromise } = useToaster();
	const showAuthModal = useAuthModal('post');

	const updateItem = useCallback(
		(id) => {
			const item = entityStore.getEntitiesByKey(POSTS).get(id);
			onLoad(item);
		},
		[onLoad, entityStore],
	);

	const { isAuth, user } = useAuth();

	useEffect(() => {
		if (error) return;

		if (!item) {
			getPost(id, key)
				.then((id) => {
					id && updateItem(id);
				})
				.catch((e) => {
					setError(e);
				});
		} else {
			updateItem(item.id);
		}
	}, [id, key, item, error, updateItem]);

	useEffect(() => {
		if (item?.id && item.permissions.can_post_view) Reach('post_view', { id: item.id, ck: item.ck });
		if (item) setTitle(item.seo?.title || item.header || 'Пост #' + item.id + TITLE_SUFFIX);
	}, [item]);

	const handlePressSubscribe = (action, id) => {
		if (!isAuth) return showAuthModal();
		actionGroup(action, id);
	};

	const handleDeleteItem = (id) => {
		deletePost(id, 'delete');
	};

	const handleRestoreItem = (id) => {
		deletePost(id, 'restore');
	};

	const handleShow = (id, ck) => {
		Reach('post_reach', { id, ck });
	};

	const handleOpen = (id, ck) => {
		Reach('post_open', { id, ck });
	};

	const handleHideItem = (id) => {
		const entity = toJS(entityStore.getEntitiesByKey(POSTS).get(id));
		entity.hide = true;
		entityStore.syncEntities(entity, POSTS);
	};

	const handleEvent = (item, action, params = {}) => {
		if (item.permissions?.can_post_view) Reach(action, { id: item.id, ck: item.ck, ...params });
	};

	const handleBookmark = (action) => {
		toastPromise(actionPost(action ? 'mark' : 'unmark', item?.id), {
			loading: 'Отправка',
			success: action ? 'Пост добавлен в закладки' : 'Пост удалён из закладок',
			error: 'Ошибка отправки',
		});
	};

	if (error) return <Error e={error} />;
	if (!item) return <Spinner fullscreen />;

	if (item?.permissions?.can_post_view === false)
		return <Error code={403} text="Доступ к контенту закрытой группы ограничен" />;

	const hasAdv = item.group && GROUPS_WITH_ADV.includes(item.group.id) && !item.params.adver;
	const userId = user?.id;

	return (
		<>
			{!modal && hasAdv && <Adv id="adfox_151731223791016696" className="py-2" />}

			<PostView
				{...item}
				footer={<Footer {...{ item, userId }} />}
				onPressSubscribe={handlePressSubscribe}
				onDelete={handleDeleteItem}
				onRestore={handleRestoreItem}
				onHide={handleHideItem}
				onBookmark={handleBookmark}
				onEvent={(action, params) => handleEvent(item, action, params)}
				onClose={onClose}
				modal={modal}
				isAuth={isAuth}
				editMode={!!editMode}
			/>

			{!modal && hasAdv && (
				<Suspense>
					<Stack>
						<Adv id="adfox_165267629953331552" className="pt-4" />
						<PostListRelatedContainer
							id={id}
							alias={item?.decoration?.header?.url}
							renderItem={(item) => (
								<PostItem
									{...item}
									onDelete={handleDeleteItem}
									onRestore={handleRestoreItem}
									onHide={handleHideItem}
									onPressSubscribe={handlePressSubscribe}
									onShow={handleShow}
									onOpen={handleOpen}
									onEvent={(action, params) => handleEvent(item, action, params)}
									isAuth={isAuth}
								/>
							)}
						/>
					</Stack>
				</Suspense>
			)}
		</>
	);
};

export default inject('entityStore', 'requestStore', 'requestStore')(observer(PostViewContainer));
