import { uploadAttachment } from 'actions/editor';
import { makeAutoObservable } from 'mobx';

class AttachmentModel {
	id = null;
	type = null;
	context = null;
	uploading = false;
	progress = null;
	objectId = null;
	objectType = null;

	constructor() {
		makeAutoObservable(this);
	}

	setUploading(value) {
		this.uploading = value;
	}

	setProgress(value) {
		this.progress = value;
	}

	setObjectId(value) {
		this.objectId = value;
	}

	setObjectType(value) {
		this.objectType = value;
	}

	setData(data) {
		this.id = data.id;
		this.type = data.type;
		this.context = data.context;
	}

	upload(file) {
		if (!file) return;

		this.setUploading(true);

		return new Promise((resolve, reject) => {
			uploadAttachment(
				file,
				{
					type: 'file',
					object_type: this.objectType,
					object_id: this.objectId,
				},
				(e) => {
					this.setProgress(Math.round((100 * e.loaded) / e.total));
				}
			)
				.then((data) => {
					this.setProgress(100);
					this.setData(data);
					this.setUploading(false);
					resolve();
				})
				.catch((e) => {
					console.error(e);
					this.setUploading(false);
					reject();
				});
		});
	}
}

class MessageModel {
	id = null;
	chatId = null;
	parentId = null;
	text = '';
	attachments = '';
	objectType = 3;

	constructor(chatId) {
		this.chatId = chatId;
		makeAutoObservable(this);
	}

	isEmpty() {
		return !this.text?.length > 0;
	}

	setText(value) {
		this.text = value;
	}

	setParent(id) {
		this.parentId = id;
	}

	setItem(item) {
		this.id = item?.id;
		this.parentId = item?.parent_id;
		this.chatId = item?.chat_id;
		this.text = item?.message?.text;
	}

	getText() {
		return this.text || '';
	}

	clear() {
		this.id = null;
		this.text = '';
		this.parentId = null;
	}

	get objectId() {
		return this.chatId;
	}
}

class CommentModel {
	id = null;
	postId = null;
	text = '';
	attachments = '';
	objectType = 5;

	constructor(postId) {
		this.postId = postId;
		makeAutoObservable(this);
	}

	isEmpty() {
		return !this.text?.length > 0;
	}

	setText(value) {
		this.text = value;
	}

	setItem(item) {
		this.id = item?.id;
		this.postId = item?.postId;
		this.text = item?.text;
	}

	getText() {
		return this.text || '';
	}

	clear() {
		this.text = '';
	}

	get objectId() {
		return this.postId;
	}
}

export { AttachmentModel, MessageModel, CommentModel };
