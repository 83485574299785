import { ChevronRightIcon, LinkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { POST, ICON } from 'constants/Size';
import { getHostname } from 'helpers/utils';
import { Stack, Photo, Link, HStack } from 'vgui';

const SingleLink = ({ item, short, onEvent }) => {
	const { title, image, url, width } = item.context;

	const isSmall = image && width < 500;

	const handleClick = () => {
		onEvent('post_link_click', { value: item.id });
	};

	if (short)
		return (
			<div className="attachment-link">
				<Link to={url} onClick={handleClick}>
					<HStack className="gap-4 py-2 pl-4 pr-2">
						<Stack>
							<h5 className="mb-1">{title}</h5>
							<HStack className="gap-1 text-sm text-muted items-center">
								<LinkIcon className="w-3 mr-1" />
								{getHostname(url)}
							</HStack>
						</Stack>
						<ChevronRightIcon className="w-5 h-5 shrink-0" />
					</HStack>
				</Link>
			</div>
		);

	return (
		<div className={classNames('attachment-link', { 'vstack p-4 flex-row-reverse': isSmall })}>
			{image && (
				<Link to={url} className="shrink-0" onClick={handleClick}>
					<Photo
						url={image}
						size={isSmall ? ICON : POST}
						mode="crop"
						alt={title || 'img'}
						placeholder
						className={classNames({ 'rounded-b-none': !isSmall })}
					/>
				</Link>
			)}
			<Stack className={classNames('justify-between space-y-2', { 'p-4': !isSmall }, { 'mr-4': isSmall })}>
				{title && (
					<Link to={url} onClick={handleClick}>
						<h4 className="mb-0">{title}</h4>
					</Link>
				)}
				<Link to={url} className="hover:underline hstack font-medium text-sm text-muted" onClick={handleClick}>
					<LinkIcon className="w-3 mr-1" />
					{getHostname(url)}
				</Link>
			</Stack>
		</div>
	);
};

export const AttachmentLink = ({ items, short = false, onEvent }) => {
	if (!items) return null;
	return <SingleLink item={items[0]} {...{ onEvent, short }} />;
};
