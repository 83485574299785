import classNames from 'classnames';
import { ADFOX_PLACES } from 'constants/Config';
import { memo, useEffect } from 'react';
import ReactAdFox from 'services/ReactAdFox';

import { Skeleton } from './Skeleton';

const Adv = ({ id, className, sticky = false }) => {
	const item = ADFOX_PLACES[id];
	const pv = window.location.search.includes('??');

	useEffect(() => {
		if (!pv && item && item.visible) ReactAdFox.put(id, item.params);
		return () => !pv && ReactAdFox.del(id);
	}, [id, pv, item]);

	if (!item || !item.visible) return null;

	return (
		<div id={id} className={classNames('apb flex justify-center', sticky && '!sticky top-4', className)}>
			{pv && <Skeleton id={id} {...item} />}
		</div>
	);
};

export default memo(Adv, (a, b) => a.id === b.id);
