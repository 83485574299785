import { Tracker } from 'modules/Analytics';
import { lazy, Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import { ModalProvider } from 'react-modal-hook';
import { Outlet } from 'react-router-dom';
import { Container, Spinner } from 'vgui';

import CookieNote from './CookieNote';

const LayoutContainer = lazy(() => import('../containers/LayoutContainer'));
const Styling = lazy(() => import('./Styling'));
const Favicon = lazy(() => import('./Favicon'));

const Root = () => {
	return (
		<Container>
			<ModalProvider>
				<LayoutContainer>
					<Tracker />
					<Suspense fallback={<Spinner fullscreen size="md" />}>
						<Outlet />
					</Suspense>
				</LayoutContainer>
			</ModalProvider>
			<Styling />
			<Favicon />
			<Toaster />
			<CookieNote />
		</Container>
	);
};

export default Root;
