import { ArrowPathIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { COMMENTS, PROFILE } from 'constants/Types';
import { plural } from 'helpers';
import scrollToElement from 'helpers/scrollToElement';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import useAuth from 'modules/Auth/hooks/useAuth';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Stack, ItemList, HStack, Button } from 'vgui';

import '../style.css';

import { fetchComments, patchComment, deleteComment } from '../actions';
import CommentItem from '../components/CommentItem';
import CommentEditContainer from './CommentEditContainer';

const renderCommentItem = ({ filter, entityStore, isAuth, user, ...props }) => {
	const handleDeleteItem = (id) => {
		deleteComment(id, 'delete');
	};

	const handleStashItem = (id) => {
		patchComment(filter, id, { action: 'hide' });
	};

	const handleRestoreItem = (id) => {
		deleteComment(id, 'restore');
	};

	const handleVote = (id, vote) => {
		let item = toJS(entityStore.getEntitiesByKey(COMMENTS).get(id));
		if (!item) return;

		if (item.voted) {
			if (item.voted === 'y') {
				item.rating.yes--;
			} else {
				item.rating.no--;
			}
		}

		if (item.voted === vote) {
			item.voted = null;
			vote = '';
		} else {
			item.voted = vote;
			if (!item.rating) item.rating = { yes: 0, no: 0 };

			if (vote === 'y') {
				item.rating.yes++;
			} else {
				item.rating.no++;
			}
		}

		entityStore.syncEntities(item, COMMENTS);
		patchComment(filter, id, { rating: vote });
	};

	const handleHideItem = (id) => {
		const entity = toJS(entityStore.getEntitiesByKey(COMMENTS).get(id));

		entity.hide = true;

		entityStore.syncEntities(entity, COMMENTS);
	};

	return (
		<CommentItem
			{...props}
			isAuth={isAuth}
			me={user?.id === props.author}
			filter={filter}
			profiles={entityStore.getEntitiesByKey(PROFILE)}
			onPressVote={handleVote}
			onStash={handleStashItem}
			onDelete={handleDeleteItem}
			onRestore={handleRestoreItem}
			onHide={handleHideItem}
		/>
	);
};

const CommentListContainer = (props) => {
	const location = useLocation();
	const { isAuth, user } = useAuth();

	const { filter, listStore, entityStore, paginateStore, requestStore } = props;

	const [error, setError] = useState(null);
	const [replyId, setReplyId] = useState(null);
	const [editId, setEdit] = useState(null);

	const ids = listStore.getByType(filter.key);
	const totalCount = paginateStore.getCounters(filter.key);
	const expired = paginateStore.isExpired(filter.key);
	const loading = requestStore.getRequestByType(filter.key);
	const loaded = loading !== undefined;

	useEffect(() => {
		if (expired)
			fetchComments(filter, true).catch((e) => {
				setError(e.response?.data || e.response);
			});
	}, [filter, expired]);

	useEffect(() => {
		if (ids && location.hash) setTimeout(() => scrollToElement(location.hash.substring(1), 'auto'), 100);
	}, [location, ids]);

	const handleReload = () => {
		fetchComments(filter, true);
	};

	if (loading === undefined) return;

	if (error)
		return (
			<Stack id="comments">
				<div className="text-center py-2 text-sm text-danger">{error.message}</div>
			</Stack>
		);

	const entities = entityStore.getEntitiesByKey(COMMENTS);

	let title = loading ? '...' : (totalCount || 'Нет') + ' комментари' + plural(totalCount, 'й', 'я', 'ев');

	return (
		<Stack id="comments">
			<HStack className="justify-between items-center mb-6">
				{title ? <div className="text-lg flex items-start">{title}</div> : ''}
				<Button round size="rect" variant="secondary" onClick={handleReload}>
					<ArrowPathIcon className={classNames('w-5 h-5', loading && 'animate-spin')} />
				</Button>
			</HStack>
			<ItemList
				ids={ids}
				entities={entities}
				renderItem={(item) =>
					renderCommentItem({
						...item,
						isAuth,
						user,
						entityStore,
						filter,
						reply: [replyId, setReplyId],
						edit: [editId, setEdit],
					})
				}
				className="item-list"
			/>
			{loaded && !replyId && !editId && (
				<div className="pt-4 -mx-4 px-2 border-t border-dotted" data-edit={editId}>
					<CommentEditContainer
						filter={filter}
						reply={[replyId, setReplyId]}
						edit={[editId, setEdit]}
						disabled={loading}
					/>
				</div>
			)}
		</Stack>
	);
};

export default inject('paginateStore', 'listStore', 'entityStore', 'requestStore')(observer(CommentListContainer));
