import { CHAT, PROFILE } from 'constants/Types';
import { toQueryString } from 'helpers';
import { dateISO } from 'helpers/date';
import { get } from 'helpers/utils';
import { toJS } from 'mobx';
import { normalize } from 'normalizr';
import { chatSchema, messageSchema } from 'schemas';
import api from 'services/api';
import { chatStore, messageStore, layoutStore, entityStore, profileStore, paginateStore } from 'stores';

export function fetchChatList(filter, clear = false) {
	const nextHref = chatStore.getNext(filter.key);
	if (!nextHref && !clear) return;

	if (chatStore.getRequestByType(filter.key)) return Promise.resolve();
	chatStore.setRequestInProcess(filter.key, true);

	let params = {
		// type: filter.type,
	};

	if (filter.group_id) params.group_id = filter.group_id;

	const initHref = '/v1/chat?' + toQueryString(params);
	const url = (clear ? null : nextHref) || initHref;

	return api
		.get(url, clear)
		.then(({ data }) => {
			if (clear) chatStore.clear(filter.key);
			const normalized = normalize(data, { items: [chatSchema] });
			chatStore.concat(filter.key, normalized.result.items || []);
			if (normalized.result.items) {
				chatStore.merge(normalized.entities.chat);
				messageStore.merge(normalized.entities.message);
				chatStore.setMeta(filter.key, data._meta, data._links);
				entityStore.mergeEntities(PROFILE, normalized.entities.profile);
			} else {
				chatStore.concat(filter.key, []);
				chatStore.merge([]);
			}

			chatStore.setRequestInProcess(filter.key, false);
			return Promise.resolve();
		})
		.catch((error) => {
			chatStore.setRequestInProcess(filter.key, false);
			return Promise.reject(error);
		});
}

export function getChat(id) {
	return api.GET(`/v1/chat/${id}`).then((data) => {
		const normalized = normalize(data, chatSchema);
		if (normalized.result) {
			chatStore.merge(normalized.entities.chat);
			messageStore.merge(normalized.entities.message);
			return Promise.resolve(normalized.result);
		}
	});
}

export function updateChat(id, data) {
	return api.PATCH(`/v1/chat/${id}`, data).then((data) => {
		const normalized = normalize(data, chatSchema);
		if (normalized.result) {
			chatStore.merge(normalized.entities.chat);
			messageStore.merge(normalized.entities.message);
			return Promise.resolve(normalized.result);
		}
	});
}

export function deleteChat(chatId) {
	return api.delete(`/v1/chat/${chatId}`).then(() => {
		chatStore.delete(CHAT, chatId);
		return Promise.resolve();
	});
}

export function fetchMessageList(filter, clear = false) {
	const nextHref = messageStore.getNext(filter.key);

	if (messageStore.getRequestByType(filter.key)) return Promise.resolve();
	messageStore.setRequestInProcess(filter.key, true);

	let params = {
		chat_id: filter.chatId,
	};

	const initHref = '/v1/message?' + toQueryString(params);
	const url = (clear ? null : nextHref) || initHref;

	return api
		.get(url, clear)
		.then(({ data }) => {
			if (clear) messageStore.clear(filter.key);
			const normalized = normalize(data, { items: [messageSchema] });
			messageStore.concat(filter.key, normalized.result.items || []);

			if (normalized.result.items) {
				messageStore.merge(normalized.entities.message);
				messageStore.setMeta(filter.key, data._meta, data._links);
				entityStore.mergeEntities(PROFILE, normalized.entities.profile);
			}
			return Promise.resolve(normalized.entities.profile);
		})
		.catch((e) => {
			return Promise.reject(e.response?.data || e.response);
		})
		.finally(() => {
			messageStore.setRequestInProcess(filter.key, false);
		});
}

export function fetchMessageMembers(filter, clear = true) {
	const requestType = filter.key;

	const nextHref = messageStore.getNext(filter.key);
	if (nextHref === undefined && !clear) {
		messageStore.setRequestInProcess(requestType, false);
		return;
	}

	if (messageStore.getRequestByType(requestType)) return Promise.reject();
	messageStore.setRequestInProcess(requestType, true);

	let params = {
		chat_id: filter.chatId,
	};

	const initHref = '/v1/chat-member?' + toQueryString(params);
	const url = clear ? initHref : nextHref;

	return api
		.get(url)
		.then(({ data: rawData }) => {
			if (clear) entityStore.clear(requestType);

			const { _meta, ...data } = rawData;

			profileStore.setMeta(requestType, _meta);

			paginateStore.setPaginateLink(requestType, get(data, '_links.next.href'));
			messageStore.setRequestInProcess(requestType, false);
			return Promise.resolve(data.items);
		})
		.catch((error) => {
			messageStore.setRequestInProcess(requestType, false);
			return Promise.reject(error);
		});
}

export function selectChatItem(filter, chatId = 0) {
	chatStore.ids(filter.key)?.forEach((id) => chatStore.updateEntity(id, { select: chatId === id }));
}

export function updateMessage(data) {
	const normalized = normalize(data, messageSchema);
	if (normalized.result) {
		const chatId = Number(data.chat_id);
		messageStore.merge(normalized.entities.message);
		messageStore.prepend(CHAT + '_' + chatId, [normalized.result]);
		chatStore.updateEntity(chatId, { last: messageStore.ids(CHAT + '_' + chatId)[0] });
		chatStore.sort(CHAT, chatId);
		return Promise.resolve(normalized.result);
	}
}

export async function sendMessage(data) {
	return api.POST(`/v1/message`, data).then(updateMessage);
}

export async function patchMessage(id, data) {
	return api.PATCH(`/v1/message/${id}`, data).then(updateMessage);
}

export async function deleteMessage(id) {
	const item = toJS(messageStore.getEntity(id));
	messageStore.updateEntity(id, { deleted: true, message: { ...item.message, text: '[сообщение удалено]' } });

	return api.delete(`/v1/message/${id}`);
}

export function sendMessagePrivate(ids) {
	return api.POST(`/v1/chat`, {
		ids: ids,
	});
}

export function addMessage(message) {
	const normalized = normalize(message, messageSchema);
	const chatId = Number(message.chat_id);
	const messageId = Number(message.id);

	messageStore.merge(normalized.entities.message);
	messageStore.prepend(CHAT + '_' + chatId, [normalized.result]);
	entityStore.mergeEntities(PROFILE, normalized.entities.profile);

	chatStore.updateEntity(chatId, {
		last: messageId,
		datetime_update: dateISO(),
	});

	chatStore.sort(CHAT, chatId);
}

export function getMessage(id) {
	const item = messageStore.getEntity(id);
	if (!item) return null;

	const sender = entityStore.getEntitiesByKey(PROFILE).get(item.user_id);
	return { ...item, sender: sender };
}

export function addChat() {
	fetchChatList({ key: CHAT }, true);
}

export function setStatus(items) {
	items.forEach((i) => {
		const item = messageStore.getEntity(i.id);
		if (item) messageStore.updateEntity(i.id, { status: i.status });
	});
}

export function sendStatus(chatId, list) {
	const requestType = CHAT + '_READ';

	if (chatStore.getRequestByType(requestType)) return Promise.resolve();
	chatStore.setRequestInProcess(requestType, true);

	return api
		.post(`/v1/message/read`, {
			chat_id: chatId,
			ids: list,
		})
		.then(() => {
			setCountersMessages({ 0: { id: chatId, new: 0 } });
			setCounterChat(0);
			return Promise.resolve();
		})
		.finally(() => chatStore.setRequestInProcess(requestType, false));
}

export function setCounterChat(value) {
	layoutStore.setCountChat(value);
}

export function setCountersMessages(items) {
	let data = {};
	for (let key in items) {
		const id = items[key]?.id;
		data[id] = items[key]?.new;
	}

	const ids = chatStore.ids(CHAT);
	ids?.forEach((id) => {
		chatStore.updateEntity(id, {
			count_new: data[id] || 0,
			counters: { unread: data[id] || 0 },
			datetime_update: dateISO(),
		});
	});
}
