import { makeAutoObservable, observable } from 'mobx';

class ListStore {
	items = observable.map({});
	meta = observable.map({});

	prepend = (type, list) => {
		const a = this.items.get(type);
		if (!a) return this.setItems(type, list);

		const b = list.filter((id) => !a.includes(id));
		this.setItems(type, b.concat(a));
	};

	append = (type, list) => {
		const a = this.items.get(type);
		if (!a) return this.setItems(type, list);

		const b = list.filter((id) => !a.includes(id));
		this.setItems(type, a.concat(b));
	};

	setItems = (type, list) => {
		return this.items.set(type, [...new Set(list)]);
	};

	setMeta = (type, meta) => {
		this.meta.set(type, meta);
	};

	getTotal = (type) => {
		return this.meta.has(type) ? this.meta.get(type).totalCount : '';
	};

	getByType(type) {
		return this.items.get(type);
	}

	has(type) {
		return this.items.has(type);
	}

	clear(type) {
		this.items.delete(type);
	}

	wipe() {
		this.items.clear();
	}

	remove(type, removeId) {
		let items = this.items.get(type);
		if (items)
			this.setItems(
				type,
				this.items.get(type).filter((id) => id !== removeId)
			);
	}
}

const listStore = makeAutoObservable(new ListStore());
export default listStore;
export { ListStore };
